// tslint:disable
/**
 * YNAB API Endpoints
 * Our API uses a REST based design, leverages the JSON data format, and relies upon HTTPS for transport. We respond with meaningful HTTP response codes and if an error occurs, we include error details in the response body.  API Documentation is at https://api.youneedabudget.com
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var Configuration = /** @class */ (function () {
    function Configuration(accessToken, basePath) {
        this.apiKey = "Bearer " + accessToken;
        this.basePath = basePath;
    }
    return Configuration;
}());
export { Configuration };
