// tslint:disable
/**
 * YNAB API Endpoints
 * Our API uses a REST based design, leverages the JSON data format, and relies upon HTTPS for transport. We respond with meaningful HTTP response codes and if an error occurs, we include error details in the response body.  API Documentation is at https://api.youneedabudget.com
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as url from "url";
// Requiring portable-fetch like this ensures that we have a global fetch function
// That makes it easier to override with modules like fetch-mock
require("portable-fetch");
var USER_AGENT = "api_client/js/1.24.0";
function convertDateToFullDateStringFormat(date) {
    // Convert to RFC 3339 "full-date" format, like "2017-11-27"
    if (date instanceof Date) {
        return date.toISOString().substring(0, 10);
    }
    else {
        return date;
    }
}
/**
 *
 * @export
 */
export var COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
    }
    return BaseAPI;
}());
export { BaseAPI };
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        return _this;
    }
    return RequiredError;
}(Error));
export { RequiredError };
/**
 * @export
 * @namespace Account
 */
export var Account;
(function (Account) {
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum[TypeEnum["Checking"] = 'checking'] = "Checking";
        TypeEnum[TypeEnum["Savings"] = 'savings'] = "Savings";
        TypeEnum[TypeEnum["Cash"] = 'cash'] = "Cash";
        TypeEnum[TypeEnum["CreditCard"] = 'creditCard'] = "CreditCard";
        TypeEnum[TypeEnum["LineOfCredit"] = 'lineOfCredit'] = "LineOfCredit";
        TypeEnum[TypeEnum["OtherAsset"] = 'otherAsset'] = "OtherAsset";
        TypeEnum[TypeEnum["OtherLiability"] = 'otherLiability'] = "OtherLiability";
        TypeEnum[TypeEnum["PayPal"] = 'payPal'] = "PayPal";
        TypeEnum[TypeEnum["MerchantAccount"] = 'merchantAccount'] = "MerchantAccount";
        TypeEnum[TypeEnum["InvestmentAccount"] = 'investmentAccount'] = "InvestmentAccount";
        TypeEnum[TypeEnum["Mortgage"] = 'mortgage'] = "Mortgage";
    })(TypeEnum = Account.TypeEnum || (Account.TypeEnum = {}));
})(Account || (Account = {}));
/**
 * @export
 * @namespace Category
 */
export var Category;
(function (Category) {
    /**
     * @export
     * @enum {string}
     */
    var GoalTypeEnum;
    (function (GoalTypeEnum) {
        GoalTypeEnum[GoalTypeEnum["TB"] = 'TB'] = "TB";
        GoalTypeEnum[GoalTypeEnum["TBD"] = 'TBD'] = "TBD";
        GoalTypeEnum[GoalTypeEnum["MF"] = 'MF'] = "MF";
        GoalTypeEnum[GoalTypeEnum["NEED"] = 'NEED'] = "NEED";
    })(GoalTypeEnum = Category.GoalTypeEnum || (Category.GoalTypeEnum = {}));
})(Category || (Category = {}));
/**
 * @export
 * @namespace SaveAccount
 */
export var SaveAccount;
(function (SaveAccount) {
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum[TypeEnum["Checking"] = 'checking'] = "Checking";
        TypeEnum[TypeEnum["Savings"] = 'savings'] = "Savings";
        TypeEnum[TypeEnum["CreditCard"] = 'creditCard'] = "CreditCard";
        TypeEnum[TypeEnum["Cash"] = 'cash'] = "Cash";
        TypeEnum[TypeEnum["LineOfCredit"] = 'lineOfCredit'] = "LineOfCredit";
        TypeEnum[TypeEnum["OtherAsset"] = 'otherAsset'] = "OtherAsset";
        TypeEnum[TypeEnum["OtherLiability"] = 'otherLiability'] = "OtherLiability";
    })(TypeEnum = SaveAccount.TypeEnum || (SaveAccount.TypeEnum = {}));
})(SaveAccount || (SaveAccount = {}));
/**
 * @export
 * @namespace SaveTransaction
 */
export var SaveTransaction;
(function (SaveTransaction) {
    /**
     * @export
     * @enum {string}
     */
    var ClearedEnum;
    (function (ClearedEnum) {
        ClearedEnum[ClearedEnum["Cleared"] = 'cleared'] = "Cleared";
        ClearedEnum[ClearedEnum["Uncleared"] = 'uncleared'] = "Uncleared";
        ClearedEnum[ClearedEnum["Reconciled"] = 'reconciled'] = "Reconciled";
    })(ClearedEnum = SaveTransaction.ClearedEnum || (SaveTransaction.ClearedEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var FlagColorEnum;
    (function (FlagColorEnum) {
        FlagColorEnum[FlagColorEnum["Red"] = 'red'] = "Red";
        FlagColorEnum[FlagColorEnum["Orange"] = 'orange'] = "Orange";
        FlagColorEnum[FlagColorEnum["Yellow"] = 'yellow'] = "Yellow";
        FlagColorEnum[FlagColorEnum["Green"] = 'green'] = "Green";
        FlagColorEnum[FlagColorEnum["Blue"] = 'blue'] = "Blue";
        FlagColorEnum[FlagColorEnum["Purple"] = 'purple'] = "Purple";
    })(FlagColorEnum = SaveTransaction.FlagColorEnum || (SaveTransaction.FlagColorEnum = {}));
})(SaveTransaction || (SaveTransaction = {}));
/**
 * @export
 * @namespace ScheduledTransactionSummary
 */
export var ScheduledTransactionSummary;
(function (ScheduledTransactionSummary) {
    /**
     * @export
     * @enum {string}
     */
    var FrequencyEnum;
    (function (FrequencyEnum) {
        FrequencyEnum[FrequencyEnum["Never"] = 'never'] = "Never";
        FrequencyEnum[FrequencyEnum["Daily"] = 'daily'] = "Daily";
        FrequencyEnum[FrequencyEnum["Weekly"] = 'weekly'] = "Weekly";
        FrequencyEnum[FrequencyEnum["EveryOtherWeek"] = 'everyOtherWeek'] = "EveryOtherWeek";
        FrequencyEnum[FrequencyEnum["TwiceAMonth"] = 'twiceAMonth'] = "TwiceAMonth";
        FrequencyEnum[FrequencyEnum["Every4Weeks"] = 'every4Weeks'] = "Every4Weeks";
        FrequencyEnum[FrequencyEnum["Monthly"] = 'monthly'] = "Monthly";
        FrequencyEnum[FrequencyEnum["EveryOtherMonth"] = 'everyOtherMonth'] = "EveryOtherMonth";
        FrequencyEnum[FrequencyEnum["Every3Months"] = 'every3Months'] = "Every3Months";
        FrequencyEnum[FrequencyEnum["Every4Months"] = 'every4Months'] = "Every4Months";
        FrequencyEnum[FrequencyEnum["TwiceAYear"] = 'twiceAYear'] = "TwiceAYear";
        FrequencyEnum[FrequencyEnum["Yearly"] = 'yearly'] = "Yearly";
        FrequencyEnum[FrequencyEnum["EveryOtherYear"] = 'everyOtherYear'] = "EveryOtherYear";
    })(FrequencyEnum = ScheduledTransactionSummary.FrequencyEnum || (ScheduledTransactionSummary.FrequencyEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var FlagColorEnum;
    (function (FlagColorEnum) {
        FlagColorEnum[FlagColorEnum["Red"] = 'red'] = "Red";
        FlagColorEnum[FlagColorEnum["Orange"] = 'orange'] = "Orange";
        FlagColorEnum[FlagColorEnum["Yellow"] = 'yellow'] = "Yellow";
        FlagColorEnum[FlagColorEnum["Green"] = 'green'] = "Green";
        FlagColorEnum[FlagColorEnum["Blue"] = 'blue'] = "Blue";
        FlagColorEnum[FlagColorEnum["Purple"] = 'purple'] = "Purple";
    })(FlagColorEnum = ScheduledTransactionSummary.FlagColorEnum || (ScheduledTransactionSummary.FlagColorEnum = {}));
})(ScheduledTransactionSummary || (ScheduledTransactionSummary = {}));
/**
 * @export
 * @namespace TransactionSummary
 */
export var TransactionSummary;
(function (TransactionSummary) {
    /**
     * @export
     * @enum {string}
     */
    var ClearedEnum;
    (function (ClearedEnum) {
        ClearedEnum[ClearedEnum["Cleared"] = 'cleared'] = "Cleared";
        ClearedEnum[ClearedEnum["Uncleared"] = 'uncleared'] = "Uncleared";
        ClearedEnum[ClearedEnum["Reconciled"] = 'reconciled'] = "Reconciled";
    })(ClearedEnum = TransactionSummary.ClearedEnum || (TransactionSummary.ClearedEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var FlagColorEnum;
    (function (FlagColorEnum) {
        FlagColorEnum[FlagColorEnum["Red"] = 'red'] = "Red";
        FlagColorEnum[FlagColorEnum["Orange"] = 'orange'] = "Orange";
        FlagColorEnum[FlagColorEnum["Yellow"] = 'yellow'] = "Yellow";
        FlagColorEnum[FlagColorEnum["Green"] = 'green'] = "Green";
        FlagColorEnum[FlagColorEnum["Blue"] = 'blue'] = "Blue";
        FlagColorEnum[FlagColorEnum["Purple"] = 'purple'] = "Purple";
    })(FlagColorEnum = TransactionSummary.FlagColorEnum || (TransactionSummary.FlagColorEnum = {}));
})(TransactionSummary || (TransactionSummary = {}));
/**
 * @export
 * @namespace HybridTransaction
 */
export var HybridTransaction;
(function (HybridTransaction) {
    /**
     * @export
     * @enum {string}
     */
    var ClearedEnum;
    (function (ClearedEnum) {
        ClearedEnum[ClearedEnum["Cleared"] = 'cleared'] = "Cleared";
        ClearedEnum[ClearedEnum["Uncleared"] = 'uncleared'] = "Uncleared";
        ClearedEnum[ClearedEnum["Reconciled"] = 'reconciled'] = "Reconciled";
    })(ClearedEnum = HybridTransaction.ClearedEnum || (HybridTransaction.ClearedEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var FlagColorEnum;
    (function (FlagColorEnum) {
        FlagColorEnum[FlagColorEnum["Red"] = 'red'] = "Red";
        FlagColorEnum[FlagColorEnum["Orange"] = 'orange'] = "Orange";
        FlagColorEnum[FlagColorEnum["Yellow"] = 'yellow'] = "Yellow";
        FlagColorEnum[FlagColorEnum["Green"] = 'green'] = "Green";
        FlagColorEnum[FlagColorEnum["Blue"] = 'blue'] = "Blue";
        FlagColorEnum[FlagColorEnum["Purple"] = 'purple'] = "Purple";
    })(FlagColorEnum = HybridTransaction.FlagColorEnum || (HybridTransaction.FlagColorEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum[TypeEnum["Transaction"] = 'transaction'] = "Transaction";
        TypeEnum[TypeEnum["Subtransaction"] = 'subtransaction'] = "Subtransaction";
    })(TypeEnum = HybridTransaction.TypeEnum || (HybridTransaction.TypeEnum = {}));
})(HybridTransaction || (HybridTransaction = {}));
/**
 * @export
 * @namespace ScheduledTransactionDetail
 */
export var ScheduledTransactionDetail;
(function (ScheduledTransactionDetail) {
    /**
     * @export
     * @enum {string}
     */
    var FrequencyEnum;
    (function (FrequencyEnum) {
        FrequencyEnum[FrequencyEnum["Never"] = 'never'] = "Never";
        FrequencyEnum[FrequencyEnum["Daily"] = 'daily'] = "Daily";
        FrequencyEnum[FrequencyEnum["Weekly"] = 'weekly'] = "Weekly";
        FrequencyEnum[FrequencyEnum["EveryOtherWeek"] = 'everyOtherWeek'] = "EveryOtherWeek";
        FrequencyEnum[FrequencyEnum["TwiceAMonth"] = 'twiceAMonth'] = "TwiceAMonth";
        FrequencyEnum[FrequencyEnum["Every4Weeks"] = 'every4Weeks'] = "Every4Weeks";
        FrequencyEnum[FrequencyEnum["Monthly"] = 'monthly'] = "Monthly";
        FrequencyEnum[FrequencyEnum["EveryOtherMonth"] = 'everyOtherMonth'] = "EveryOtherMonth";
        FrequencyEnum[FrequencyEnum["Every3Months"] = 'every3Months'] = "Every3Months";
        FrequencyEnum[FrequencyEnum["Every4Months"] = 'every4Months'] = "Every4Months";
        FrequencyEnum[FrequencyEnum["TwiceAYear"] = 'twiceAYear'] = "TwiceAYear";
        FrequencyEnum[FrequencyEnum["Yearly"] = 'yearly'] = "Yearly";
        FrequencyEnum[FrequencyEnum["EveryOtherYear"] = 'everyOtherYear'] = "EveryOtherYear";
    })(FrequencyEnum = ScheduledTransactionDetail.FrequencyEnum || (ScheduledTransactionDetail.FrequencyEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var FlagColorEnum;
    (function (FlagColorEnum) {
        FlagColorEnum[FlagColorEnum["Red"] = 'red'] = "Red";
        FlagColorEnum[FlagColorEnum["Orange"] = 'orange'] = "Orange";
        FlagColorEnum[FlagColorEnum["Yellow"] = 'yellow'] = "Yellow";
        FlagColorEnum[FlagColorEnum["Green"] = 'green'] = "Green";
        FlagColorEnum[FlagColorEnum["Blue"] = 'blue'] = "Blue";
        FlagColorEnum[FlagColorEnum["Purple"] = 'purple'] = "Purple";
    })(FlagColorEnum = ScheduledTransactionDetail.FlagColorEnum || (ScheduledTransactionDetail.FlagColorEnum = {}));
})(ScheduledTransactionDetail || (ScheduledTransactionDetail = {}));
/**
 * @export
 * @namespace TransactionDetail
 */
export var TransactionDetail;
(function (TransactionDetail) {
    /**
     * @export
     * @enum {string}
     */
    var ClearedEnum;
    (function (ClearedEnum) {
        ClearedEnum[ClearedEnum["Cleared"] = 'cleared'] = "Cleared";
        ClearedEnum[ClearedEnum["Uncleared"] = 'uncleared'] = "Uncleared";
        ClearedEnum[ClearedEnum["Reconciled"] = 'reconciled'] = "Reconciled";
    })(ClearedEnum = TransactionDetail.ClearedEnum || (TransactionDetail.ClearedEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var FlagColorEnum;
    (function (FlagColorEnum) {
        FlagColorEnum[FlagColorEnum["Red"] = 'red'] = "Red";
        FlagColorEnum[FlagColorEnum["Orange"] = 'orange'] = "Orange";
        FlagColorEnum[FlagColorEnum["Yellow"] = 'yellow'] = "Yellow";
        FlagColorEnum[FlagColorEnum["Green"] = 'green'] = "Green";
        FlagColorEnum[FlagColorEnum["Blue"] = 'blue'] = "Blue";
        FlagColorEnum[FlagColorEnum["Purple"] = 'purple'] = "Purple";
    })(FlagColorEnum = TransactionDetail.FlagColorEnum || (TransactionDetail.FlagColorEnum = {}));
})(TransactionDetail || (TransactionDetail = {}));
/**
 * @export
 * @namespace UpdateTransaction
 */
export var UpdateTransaction;
(function (UpdateTransaction) {
    /**
     * @export
     * @enum {string}
     */
    var ClearedEnum;
    (function (ClearedEnum) {
        ClearedEnum[ClearedEnum["Cleared"] = 'cleared'] = "Cleared";
        ClearedEnum[ClearedEnum["Uncleared"] = 'uncleared'] = "Uncleared";
        ClearedEnum[ClearedEnum["Reconciled"] = 'reconciled'] = "Reconciled";
    })(ClearedEnum = UpdateTransaction.ClearedEnum || (UpdateTransaction.ClearedEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var FlagColorEnum;
    (function (FlagColorEnum) {
        FlagColorEnum[FlagColorEnum["Red"] = 'red'] = "Red";
        FlagColorEnum[FlagColorEnum["Orange"] = 'orange'] = "Orange";
        FlagColorEnum[FlagColorEnum["Yellow"] = 'yellow'] = "Yellow";
        FlagColorEnum[FlagColorEnum["Green"] = 'green'] = "Green";
        FlagColorEnum[FlagColorEnum["Blue"] = 'blue'] = "Blue";
        FlagColorEnum[FlagColorEnum["Purple"] = 'purple'] = "Purple";
    })(FlagColorEnum = UpdateTransaction.FlagColorEnum || (UpdateTransaction.FlagColorEnum = {}));
})(UpdateTransaction || (UpdateTransaction = {}));
/**
 * AccountsApi - fetch parameter creator
 * @export
 */
export var AccountsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Creates a new account
         * @summary Create a new account
         * @param {string} budget_id - The id of the budget (\"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget)
         * @param {SaveAccountWrapper} data - The account to create.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        createAccount: function (budget_id, data, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling createAccount.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data', 'Required parameter data was null or undefined when calling createAccount.');
            }
            var localVarPath = "/budgets/{budget_id}/accounts"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = JSON.stringify(data || {});
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single account
         * @summary Single account
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} account_id - The id of the account
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getAccountById: function (budget_id, account_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getAccountById.');
            }
            // verify required parameter 'account_id' is not null or undefined
            if (account_id === null || account_id === undefined) {
                throw new RequiredError('account_id', 'Required parameter account_id was null or undefined when calling getAccountById.');
            }
            var localVarPath = "/budgets/{budget_id}/accounts/{account_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "account_id" + "}", encodeURIComponent(String(account_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all accounts
         * @summary Account list
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getAccounts: function (budget_id, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getAccounts.');
            }
            var localVarPath = "/budgets/{budget_id}/accounts"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountsApi - functional programming interface
 * @export
 */
export var AccountsApiFp = function (configuration) {
    return {
        /**
         * Creates a new account
         * @summary Create a new account
         * @param {string} budget_id - The id of the budget (\"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget)
         * @param {SaveAccountWrapper} data - The account to create.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        createAccount: function (budget_id, data, options) {
            var _this = this;
            var localVarFetchArgs = AccountsApiFetchParamCreator(configuration).createAccount(budget_id, data, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns a single account
         * @summary Single account
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} account_id - The id of the account
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getAccountById: function (budget_id, account_id, options) {
            var _this = this;
            var localVarFetchArgs = AccountsApiFetchParamCreator(configuration).getAccountById(budget_id, account_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all accounts
         * @summary Account list
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getAccounts: function (budget_id, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = AccountsApiFetchParamCreator(configuration).getAccounts(budget_id, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * AccountsApi - factory interface
 * @export
 */
export var AccountsApiFactory = function (configuration) {
    return {
        /**
         * Creates a new account
         * @summary Create a new account
         * @param {string} budget_id - The id of the budget (\"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget)
         * @param {SaveAccountWrapper} data - The account to create.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        createAccount: function (budget_id, data, options) {
            return AccountsApiFp(configuration).createAccount(budget_id, data, options)();
        },
        /**
         * Returns a single account
         * @summary Single account
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} account_id - The id of the account
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getAccountById: function (budget_id, account_id, options) {
            return AccountsApiFp(configuration).getAccountById(budget_id, account_id, options)();
        },
        /**
         * Returns all accounts
         * @summary Account list
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getAccounts: function (budget_id, last_knowledge_of_server, options) {
            return AccountsApiFp(configuration).getAccounts(budget_id, last_knowledge_of_server, options)();
        },
    };
};
/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
var AccountsApi = /** @class */ (function (_super) {
    __extends(AccountsApi, _super);
    function AccountsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Creates a new account
     * @summary Create a new account
     * @param {string} budget_id - The id of the budget (\"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget)
     * @param {SaveAccountWrapper} data - The account to create.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.createAccount = function (budget_id, data, options) {
        return AccountsApiFp(this.configuration).createAccount(budget_id, data, options)();
    };
    /**
     * Returns a single account
     * @summary Single account
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} account_id - The id of the account
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getAccountById = function (budget_id, account_id, options) {
        return AccountsApiFp(this.configuration).getAccountById(budget_id, account_id, options)();
    };
    /**
     * Returns all accounts
     * @summary Account list
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getAccounts = function (budget_id, last_knowledge_of_server, options) {
        return AccountsApiFp(this.configuration).getAccounts(budget_id, last_knowledge_of_server, options)();
    };
    return AccountsApi;
}(BaseAPI));
export { AccountsApi };
/**
 * BudgetsApi - fetch parameter creator
 * @export
 */
export var BudgetsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Returns a single budget with all related entities.  This resource is effectively a full budget export.
         * @summary Single budget
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetById: function (budget_id, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getBudgetById.');
            }
            var localVarPath = "/budgets/{budget_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns settings for a budget
         * @summary Budget Settings
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetSettingsById: function (budget_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getBudgetSettingsById.');
            }
            var localVarPath = "/budgets/{budget_id}/settings"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns budgets list with summary information
         * @summary List budgets
         * @param {boolean} [include_accounts] - Whether to include the list of budget accounts
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgets: function (include_accounts, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/budgets";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (include_accounts !== undefined) {
                localVarQueryParameter['include_accounts'] = include_accounts;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BudgetsApi - functional programming interface
 * @export
 */
export var BudgetsApiFp = function (configuration) {
    return {
        /**
         * Returns a single budget with all related entities.  This resource is effectively a full budget export.
         * @summary Single budget
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetById: function (budget_id, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = BudgetsApiFetchParamCreator(configuration).getBudgetById(budget_id, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns settings for a budget
         * @summary Budget Settings
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetSettingsById: function (budget_id, options) {
            var _this = this;
            var localVarFetchArgs = BudgetsApiFetchParamCreator(configuration).getBudgetSettingsById(budget_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns budgets list with summary information
         * @summary List budgets
         * @param {boolean} [include_accounts] - Whether to include the list of budget accounts
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgets: function (include_accounts, options) {
            var _this = this;
            var localVarFetchArgs = BudgetsApiFetchParamCreator(configuration).getBudgets(include_accounts, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * BudgetsApi - factory interface
 * @export
 */
export var BudgetsApiFactory = function (configuration) {
    return {
        /**
         * Returns a single budget with all related entities.  This resource is effectively a full budget export.
         * @summary Single budget
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetById: function (budget_id, last_knowledge_of_server, options) {
            return BudgetsApiFp(configuration).getBudgetById(budget_id, last_knowledge_of_server, options)();
        },
        /**
         * Returns settings for a budget
         * @summary Budget Settings
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetSettingsById: function (budget_id, options) {
            return BudgetsApiFp(configuration).getBudgetSettingsById(budget_id, options)();
        },
        /**
         * Returns budgets list with summary information
         * @summary List budgets
         * @param {boolean} [include_accounts] - Whether to include the list of budget accounts
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgets: function (include_accounts, options) {
            return BudgetsApiFp(configuration).getBudgets(include_accounts, options)();
        },
    };
};
/**
 * BudgetsApi - object-oriented interface
 * @export
 * @class BudgetsApi
 * @extends {BaseAPI}
 */
var BudgetsApi = /** @class */ (function (_super) {
    __extends(BudgetsApi, _super);
    function BudgetsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns a single budget with all related entities.  This resource is effectively a full budget export.
     * @summary Single budget
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof BudgetsApi
     */
    BudgetsApi.prototype.getBudgetById = function (budget_id, last_knowledge_of_server, options) {
        return BudgetsApiFp(this.configuration).getBudgetById(budget_id, last_knowledge_of_server, options)();
    };
    /**
     * Returns settings for a budget
     * @summary Budget Settings
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof BudgetsApi
     */
    BudgetsApi.prototype.getBudgetSettingsById = function (budget_id, options) {
        return BudgetsApiFp(this.configuration).getBudgetSettingsById(budget_id, options)();
    };
    /**
     * Returns budgets list with summary information
     * @summary List budgets
     * @param {boolean} [include_accounts] - Whether to include the list of budget accounts
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof BudgetsApi
     */
    BudgetsApi.prototype.getBudgets = function (include_accounts, options) {
        return BudgetsApiFp(this.configuration).getBudgets(include_accounts, options)();
    };
    return BudgetsApi;
}(BaseAPI));
export { BudgetsApi };
/**
 * CategoriesApi - fetch parameter creator
 * @export
 */
export var CategoriesApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Returns all categories grouped by category group.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary List categories
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getCategories: function (budget_id, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getCategories.');
            }
            var localVarPath = "/budgets/{budget_id}/categories"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single category.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary Single category
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} category_id - The id of the category
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getCategoryById: function (budget_id, category_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getCategoryById.');
            }
            // verify required parameter 'category_id' is not null or undefined
            if (category_id === null || category_id === undefined) {
                throw new RequiredError('category_id', 'Required parameter category_id was null or undefined when calling getCategoryById.');
            }
            var localVarPath = "/budgets/{budget_id}/categories/{category_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "category_id" + "}", encodeURIComponent(String(category_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single category for a specific budget month.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary Single category for a specific budget month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {string} category_id - The id of the category
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getMonthCategoryById: function (budget_id, month, category_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getMonthCategoryById.');
            }
            // verify required parameter 'month' is not null or undefined
            if (month === null || month === undefined) {
                throw new RequiredError('month', 'Required parameter month was null or undefined when calling getMonthCategoryById.');
            }
            // verify required parameter 'category_id' is not null or undefined
            if (category_id === null || category_id === undefined) {
                throw new RequiredError('category_id', 'Required parameter category_id was null or undefined when calling getMonthCategoryById.');
            }
            var localVarPath = "/budgets/{budget_id}/months/{month}/categories/{category_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "month" + "}", encodeURIComponent(convertDateToFullDateStringFormat(month)))
                .replace("{" + "category_id" + "}", encodeURIComponent(String(category_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a category for a specific month.  Only `budgeted` amount can be updated.
         * @summary Update a category for a specific month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {string} category_id - The id of the category
         * @param {SaveMonthCategoryWrapper} data - The category to update.  Only `budgeted` amount can be updated and any other fields specified will be ignored.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateMonthCategory: function (budget_id, month, category_id, data, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling updateMonthCategory.');
            }
            // verify required parameter 'month' is not null or undefined
            if (month === null || month === undefined) {
                throw new RequiredError('month', 'Required parameter month was null or undefined when calling updateMonthCategory.');
            }
            // verify required parameter 'category_id' is not null or undefined
            if (category_id === null || category_id === undefined) {
                throw new RequiredError('category_id', 'Required parameter category_id was null or undefined when calling updateMonthCategory.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data', 'Required parameter data was null or undefined when calling updateMonthCategory.');
            }
            var localVarPath = "/budgets/{budget_id}/months/{month}/categories/{category_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "month" + "}", encodeURIComponent(convertDateToFullDateStringFormat(month)))
                .replace("{" + "category_id" + "}", encodeURIComponent(String(category_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = JSON.stringify(data || {});
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CategoriesApi - functional programming interface
 * @export
 */
export var CategoriesApiFp = function (configuration) {
    return {
        /**
         * Returns all categories grouped by category group.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary List categories
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getCategories: function (budget_id, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).getCategories(budget_id, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns a single category.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary Single category
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} category_id - The id of the category
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getCategoryById: function (budget_id, category_id, options) {
            var _this = this;
            var localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).getCategoryById(budget_id, category_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns a single category for a specific budget month.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary Single category for a specific budget month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {string} category_id - The id of the category
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getMonthCategoryById: function (budget_id, month, category_id, options) {
            var _this = this;
            var localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).getMonthCategoryById(budget_id, month, category_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Update a category for a specific month.  Only `budgeted` amount can be updated.
         * @summary Update a category for a specific month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {string} category_id - The id of the category
         * @param {SaveMonthCategoryWrapper} data - The category to update.  Only `budgeted` amount can be updated and any other fields specified will be ignored.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateMonthCategory: function (budget_id, month, category_id, data, options) {
            var _this = this;
            var localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).updateMonthCategory(budget_id, month, category_id, data, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * CategoriesApi - factory interface
 * @export
 */
export var CategoriesApiFactory = function (configuration) {
    return {
        /**
         * Returns all categories grouped by category group.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary List categories
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getCategories: function (budget_id, last_knowledge_of_server, options) {
            return CategoriesApiFp(configuration).getCategories(budget_id, last_knowledge_of_server, options)();
        },
        /**
         * Returns a single category.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary Single category
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} category_id - The id of the category
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getCategoryById: function (budget_id, category_id, options) {
            return CategoriesApiFp(configuration).getCategoryById(budget_id, category_id, options)();
        },
        /**
         * Returns a single category for a specific budget month.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
         * @summary Single category for a specific budget month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {string} category_id - The id of the category
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getMonthCategoryById: function (budget_id, month, category_id, options) {
            return CategoriesApiFp(configuration).getMonthCategoryById(budget_id, month, category_id, options)();
        },
        /**
         * Update a category for a specific month.  Only `budgeted` amount can be updated.
         * @summary Update a category for a specific month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {string} category_id - The id of the category
         * @param {SaveMonthCategoryWrapper} data - The category to update.  Only `budgeted` amount can be updated and any other fields specified will be ignored.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateMonthCategory: function (budget_id, month, category_id, data, options) {
            return CategoriesApiFp(configuration).updateMonthCategory(budget_id, month, category_id, data, options)();
        },
    };
};
/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
var CategoriesApi = /** @class */ (function (_super) {
    __extends(CategoriesApi, _super);
    function CategoriesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns all categories grouped by category group.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
     * @summary List categories
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    CategoriesApi.prototype.getCategories = function (budget_id, last_knowledge_of_server, options) {
        return CategoriesApiFp(this.configuration).getCategories(budget_id, last_knowledge_of_server, options)();
    };
    /**
     * Returns a single category.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
     * @summary Single category
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} category_id - The id of the category
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    CategoriesApi.prototype.getCategoryById = function (budget_id, category_id, options) {
        return CategoriesApiFp(this.configuration).getCategoryById(budget_id, category_id, options)();
    };
    /**
     * Returns a single category for a specific budget month.  Amounts (budgeted, activity, balance, etc.) are specific to the current budget month (UTC).
     * @summary Single category for a specific budget month
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
     * @param {string} category_id - The id of the category
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    CategoriesApi.prototype.getMonthCategoryById = function (budget_id, month, category_id, options) {
        return CategoriesApiFp(this.configuration).getMonthCategoryById(budget_id, month, category_id, options)();
    };
    /**
     * Update a category for a specific month.  Only `budgeted` amount can be updated.
     * @summary Update a category for a specific month
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
     * @param {string} category_id - The id of the category
     * @param {SaveMonthCategoryWrapper} data - The category to update.  Only `budgeted` amount can be updated and any other fields specified will be ignored.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    CategoriesApi.prototype.updateMonthCategory = function (budget_id, month, category_id, data, options) {
        return CategoriesApiFp(this.configuration).updateMonthCategory(budget_id, month, category_id, data, options)();
    };
    return CategoriesApi;
}(BaseAPI));
export { CategoriesApi };
/**
 * DeprecatedApi - fetch parameter creator
 * @export
 */
export var DeprecatedApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Creates multiple transactions.  Although this endpoint is still supported, it is recommended to use 'POST /budgets/{budget_id}/transactions' to create multiple transactions.
         * @summary Bulk create transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {BulkTransactions} transactions - The list of transactions to create
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        bulkCreateTransactions: function (budget_id, transactions, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling bulkCreateTransactions.');
            }
            // verify required parameter 'transactions' is not null or undefined
            if (transactions === null || transactions === undefined) {
                throw new RequiredError('transactions', 'Required parameter transactions was null or undefined when calling bulkCreateTransactions.');
            }
            var localVarPath = "/budgets/{budget_id}/transactions/bulk"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = JSON.stringify(transactions || {});
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DeprecatedApi - functional programming interface
 * @export
 */
export var DeprecatedApiFp = function (configuration) {
    return {
        /**
         * Creates multiple transactions.  Although this endpoint is still supported, it is recommended to use 'POST /budgets/{budget_id}/transactions' to create multiple transactions.
         * @summary Bulk create transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {BulkTransactions} transactions - The list of transactions to create
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        bulkCreateTransactions: function (budget_id, transactions, options) {
            var _this = this;
            var localVarFetchArgs = DeprecatedApiFetchParamCreator(configuration).bulkCreateTransactions(budget_id, transactions, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * DeprecatedApi - factory interface
 * @export
 */
export var DeprecatedApiFactory = function (configuration) {
    return {
        /**
         * Creates multiple transactions.  Although this endpoint is still supported, it is recommended to use 'POST /budgets/{budget_id}/transactions' to create multiple transactions.
         * @summary Bulk create transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {BulkTransactions} transactions - The list of transactions to create
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        bulkCreateTransactions: function (budget_id, transactions, options) {
            return DeprecatedApiFp(configuration).bulkCreateTransactions(budget_id, transactions, options)();
        },
    };
};
/**
 * DeprecatedApi - object-oriented interface
 * @export
 * @class DeprecatedApi
 * @extends {BaseAPI}
 */
var DeprecatedApi = /** @class */ (function (_super) {
    __extends(DeprecatedApi, _super);
    function DeprecatedApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Creates multiple transactions.  Although this endpoint is still supported, it is recommended to use 'POST /budgets/{budget_id}/transactions' to create multiple transactions.
     * @summary Bulk create transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {BulkTransactions} transactions - The list of transactions to create
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof DeprecatedApi
     */
    DeprecatedApi.prototype.bulkCreateTransactions = function (budget_id, transactions, options) {
        return DeprecatedApiFp(this.configuration).bulkCreateTransactions(budget_id, transactions, options)();
    };
    return DeprecatedApi;
}(BaseAPI));
export { DeprecatedApi };
/**
 * MonthsApi - fetch parameter creator
 * @export
 */
export var MonthsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Returns a single budget month
         * @summary Single budget month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetMonth: function (budget_id, month, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getBudgetMonth.');
            }
            // verify required parameter 'month' is not null or undefined
            if (month === null || month === undefined) {
                throw new RequiredError('month', 'Required parameter month was null or undefined when calling getBudgetMonth.');
            }
            var localVarPath = "/budgets/{budget_id}/months/{month}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "month" + "}", encodeURIComponent(convertDateToFullDateStringFormat(month)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all budget months
         * @summary List budget months
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetMonths: function (budget_id, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getBudgetMonths.');
            }
            var localVarPath = "/budgets/{budget_id}/months"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * MonthsApi - functional programming interface
 * @export
 */
export var MonthsApiFp = function (configuration) {
    return {
        /**
         * Returns a single budget month
         * @summary Single budget month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetMonth: function (budget_id, month, options) {
            var _this = this;
            var localVarFetchArgs = MonthsApiFetchParamCreator(configuration).getBudgetMonth(budget_id, month, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all budget months
         * @summary List budget months
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetMonths: function (budget_id, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = MonthsApiFetchParamCreator(configuration).getBudgetMonths(budget_id, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * MonthsApi - factory interface
 * @export
 */
export var MonthsApiFactory = function (configuration) {
    return {
        /**
         * Returns a single budget month
         * @summary Single budget month
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetMonth: function (budget_id, month, options) {
            return MonthsApiFp(configuration).getBudgetMonth(budget_id, month, options)();
        },
        /**
         * Returns all budget months
         * @summary List budget months
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getBudgetMonths: function (budget_id, last_knowledge_of_server, options) {
            return MonthsApiFp(configuration).getBudgetMonths(budget_id, last_knowledge_of_server, options)();
        },
    };
};
/**
 * MonthsApi - object-oriented interface
 * @export
 * @class MonthsApi
 * @extends {BaseAPI}
 */
var MonthsApi = /** @class */ (function (_super) {
    __extends(MonthsApi, _super);
    function MonthsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns a single budget month
     * @summary Single budget month
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {Date} month - The budget month in ISO format (e.g. 2016-12-01) (\"current\" can also be used to specify the current calendar month (UTC))
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof MonthsApi
     */
    MonthsApi.prototype.getBudgetMonth = function (budget_id, month, options) {
        return MonthsApiFp(this.configuration).getBudgetMonth(budget_id, month, options)();
    };
    /**
     * Returns all budget months
     * @summary List budget months
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof MonthsApi
     */
    MonthsApi.prototype.getBudgetMonths = function (budget_id, last_knowledge_of_server, options) {
        return MonthsApiFp(this.configuration).getBudgetMonths(budget_id, last_knowledge_of_server, options)();
    };
    return MonthsApi;
}(BaseAPI));
export { MonthsApi };
/**
 * PayeeLocationsApi - fetch parameter creator
 * @export
 */
export var PayeeLocationsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Returns a single payee location
         * @summary Single payee location
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_location_id - id of payee location
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocationById: function (budget_id, payee_location_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getPayeeLocationById.');
            }
            // verify required parameter 'payee_location_id' is not null or undefined
            if (payee_location_id === null || payee_location_id === undefined) {
                throw new RequiredError('payee_location_id', 'Required parameter payee_location_id was null or undefined when calling getPayeeLocationById.');
            }
            var localVarPath = "/budgets/{budget_id}/payee_locations/{payee_location_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "payee_location_id" + "}", encodeURIComponent(String(payee_location_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all payee locations
         * @summary List payee locations
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocations: function (budget_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getPayeeLocations.');
            }
            var localVarPath = "/budgets/{budget_id}/payee_locations"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all payee locations for a specified payee
         * @summary List locations for a payee
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - id of payee
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocationsByPayee: function (budget_id, payee_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getPayeeLocationsByPayee.');
            }
            // verify required parameter 'payee_id' is not null or undefined
            if (payee_id === null || payee_id === undefined) {
                throw new RequiredError('payee_id', 'Required parameter payee_id was null or undefined when calling getPayeeLocationsByPayee.');
            }
            var localVarPath = "/budgets/{budget_id}/payees/{payee_id}/payee_locations"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "payee_id" + "}", encodeURIComponent(String(payee_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PayeeLocationsApi - functional programming interface
 * @export
 */
export var PayeeLocationsApiFp = function (configuration) {
    return {
        /**
         * Returns a single payee location
         * @summary Single payee location
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_location_id - id of payee location
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocationById: function (budget_id, payee_location_id, options) {
            var _this = this;
            var localVarFetchArgs = PayeeLocationsApiFetchParamCreator(configuration).getPayeeLocationById(budget_id, payee_location_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all payee locations
         * @summary List payee locations
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocations: function (budget_id, options) {
            var _this = this;
            var localVarFetchArgs = PayeeLocationsApiFetchParamCreator(configuration).getPayeeLocations(budget_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all payee locations for a specified payee
         * @summary List locations for a payee
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - id of payee
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocationsByPayee: function (budget_id, payee_id, options) {
            var _this = this;
            var localVarFetchArgs = PayeeLocationsApiFetchParamCreator(configuration).getPayeeLocationsByPayee(budget_id, payee_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * PayeeLocationsApi - factory interface
 * @export
 */
export var PayeeLocationsApiFactory = function (configuration) {
    return {
        /**
         * Returns a single payee location
         * @summary Single payee location
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_location_id - id of payee location
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocationById: function (budget_id, payee_location_id, options) {
            return PayeeLocationsApiFp(configuration).getPayeeLocationById(budget_id, payee_location_id, options)();
        },
        /**
         * Returns all payee locations
         * @summary List payee locations
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocations: function (budget_id, options) {
            return PayeeLocationsApiFp(configuration).getPayeeLocations(budget_id, options)();
        },
        /**
         * Returns all payee locations for a specified payee
         * @summary List locations for a payee
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - id of payee
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeLocationsByPayee: function (budget_id, payee_id, options) {
            return PayeeLocationsApiFp(configuration).getPayeeLocationsByPayee(budget_id, payee_id, options)();
        },
    };
};
/**
 * PayeeLocationsApi - object-oriented interface
 * @export
 * @class PayeeLocationsApi
 * @extends {BaseAPI}
 */
var PayeeLocationsApi = /** @class */ (function (_super) {
    __extends(PayeeLocationsApi, _super);
    function PayeeLocationsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns a single payee location
     * @summary Single payee location
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} payee_location_id - id of payee location
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof PayeeLocationsApi
     */
    PayeeLocationsApi.prototype.getPayeeLocationById = function (budget_id, payee_location_id, options) {
        return PayeeLocationsApiFp(this.configuration).getPayeeLocationById(budget_id, payee_location_id, options)();
    };
    /**
     * Returns all payee locations
     * @summary List payee locations
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof PayeeLocationsApi
     */
    PayeeLocationsApi.prototype.getPayeeLocations = function (budget_id, options) {
        return PayeeLocationsApiFp(this.configuration).getPayeeLocations(budget_id, options)();
    };
    /**
     * Returns all payee locations for a specified payee
     * @summary List locations for a payee
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} payee_id - id of payee
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof PayeeLocationsApi
     */
    PayeeLocationsApi.prototype.getPayeeLocationsByPayee = function (budget_id, payee_id, options) {
        return PayeeLocationsApiFp(this.configuration).getPayeeLocationsByPayee(budget_id, payee_id, options)();
    };
    return PayeeLocationsApi;
}(BaseAPI));
export { PayeeLocationsApi };
/**
 * PayeesApi - fetch parameter creator
 * @export
 */
export var PayeesApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Returns a single payee
         * @summary Single payee
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - The id of the payee
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeById: function (budget_id, payee_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getPayeeById.');
            }
            // verify required parameter 'payee_id' is not null or undefined
            if (payee_id === null || payee_id === undefined) {
                throw new RequiredError('payee_id', 'Required parameter payee_id was null or undefined when calling getPayeeById.');
            }
            var localVarPath = "/budgets/{budget_id}/payees/{payee_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "payee_id" + "}", encodeURIComponent(String(payee_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all payees
         * @summary List payees
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayees: function (budget_id, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getPayees.');
            }
            var localVarPath = "/budgets/{budget_id}/payees"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PayeesApi - functional programming interface
 * @export
 */
export var PayeesApiFp = function (configuration) {
    return {
        /**
         * Returns a single payee
         * @summary Single payee
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - The id of the payee
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeById: function (budget_id, payee_id, options) {
            var _this = this;
            var localVarFetchArgs = PayeesApiFetchParamCreator(configuration).getPayeeById(budget_id, payee_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all payees
         * @summary List payees
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayees: function (budget_id, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = PayeesApiFetchParamCreator(configuration).getPayees(budget_id, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * PayeesApi - factory interface
 * @export
 */
export var PayeesApiFactory = function (configuration) {
    return {
        /**
         * Returns a single payee
         * @summary Single payee
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - The id of the payee
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayeeById: function (budget_id, payee_id, options) {
            return PayeesApiFp(configuration).getPayeeById(budget_id, payee_id, options)();
        },
        /**
         * Returns all payees
         * @summary List payees
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getPayees: function (budget_id, last_knowledge_of_server, options) {
            return PayeesApiFp(configuration).getPayees(budget_id, last_knowledge_of_server, options)();
        },
    };
};
/**
 * PayeesApi - object-oriented interface
 * @export
 * @class PayeesApi
 * @extends {BaseAPI}
 */
var PayeesApi = /** @class */ (function (_super) {
    __extends(PayeesApi, _super);
    function PayeesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns a single payee
     * @summary Single payee
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} payee_id - The id of the payee
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof PayeesApi
     */
    PayeesApi.prototype.getPayeeById = function (budget_id, payee_id, options) {
        return PayeesApiFp(this.configuration).getPayeeById(budget_id, payee_id, options)();
    };
    /**
     * Returns all payees
     * @summary List payees
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof PayeesApi
     */
    PayeesApi.prototype.getPayees = function (budget_id, last_knowledge_of_server, options) {
        return PayeesApiFp(this.configuration).getPayees(budget_id, last_knowledge_of_server, options)();
    };
    return PayeesApi;
}(BaseAPI));
export { PayeesApi };
/**
 * ScheduledTransactionsApi - fetch parameter creator
 * @export
 */
export var ScheduledTransactionsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Returns a single scheduled transaction
         * @summary Single scheduled transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} scheduled_transaction_id - The id of the scheduled transaction
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getScheduledTransactionById: function (budget_id, scheduled_transaction_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getScheduledTransactionById.');
            }
            // verify required parameter 'scheduled_transaction_id' is not null or undefined
            if (scheduled_transaction_id === null || scheduled_transaction_id === undefined) {
                throw new RequiredError('scheduled_transaction_id', 'Required parameter scheduled_transaction_id was null or undefined when calling getScheduledTransactionById.');
            }
            var localVarPath = "/budgets/{budget_id}/scheduled_transactions/{scheduled_transaction_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "scheduled_transaction_id" + "}", encodeURIComponent(String(scheduled_transaction_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all scheduled transactions
         * @summary List scheduled transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getScheduledTransactions: function (budget_id, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getScheduledTransactions.');
            }
            var localVarPath = "/budgets/{budget_id}/scheduled_transactions"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ScheduledTransactionsApi - functional programming interface
 * @export
 */
export var ScheduledTransactionsApiFp = function (configuration) {
    return {
        /**
         * Returns a single scheduled transaction
         * @summary Single scheduled transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} scheduled_transaction_id - The id of the scheduled transaction
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getScheduledTransactionById: function (budget_id, scheduled_transaction_id, options) {
            var _this = this;
            var localVarFetchArgs = ScheduledTransactionsApiFetchParamCreator(configuration).getScheduledTransactionById(budget_id, scheduled_transaction_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all scheduled transactions
         * @summary List scheduled transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getScheduledTransactions: function (budget_id, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = ScheduledTransactionsApiFetchParamCreator(configuration).getScheduledTransactions(budget_id, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * ScheduledTransactionsApi - factory interface
 * @export
 */
export var ScheduledTransactionsApiFactory = function (configuration) {
    return {
        /**
         * Returns a single scheduled transaction
         * @summary Single scheduled transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} scheduled_transaction_id - The id of the scheduled transaction
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getScheduledTransactionById: function (budget_id, scheduled_transaction_id, options) {
            return ScheduledTransactionsApiFp(configuration).getScheduledTransactionById(budget_id, scheduled_transaction_id, options)();
        },
        /**
         * Returns all scheduled transactions
         * @summary List scheduled transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getScheduledTransactions: function (budget_id, last_knowledge_of_server, options) {
            return ScheduledTransactionsApiFp(configuration).getScheduledTransactions(budget_id, last_knowledge_of_server, options)();
        },
    };
};
/**
 * ScheduledTransactionsApi - object-oriented interface
 * @export
 * @class ScheduledTransactionsApi
 * @extends {BaseAPI}
 */
var ScheduledTransactionsApi = /** @class */ (function (_super) {
    __extends(ScheduledTransactionsApi, _super);
    function ScheduledTransactionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns a single scheduled transaction
     * @summary Single scheduled transaction
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} scheduled_transaction_id - The id of the scheduled transaction
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof ScheduledTransactionsApi
     */
    ScheduledTransactionsApi.prototype.getScheduledTransactionById = function (budget_id, scheduled_transaction_id, options) {
        return ScheduledTransactionsApiFp(this.configuration).getScheduledTransactionById(budget_id, scheduled_transaction_id, options)();
    };
    /**
     * Returns all scheduled transactions
     * @summary List scheduled transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof ScheduledTransactionsApi
     */
    ScheduledTransactionsApi.prototype.getScheduledTransactions = function (budget_id, last_knowledge_of_server, options) {
        return ScheduledTransactionsApiFp(this.configuration).getScheduledTransactions(budget_id, last_knowledge_of_server, options)();
    };
    return ScheduledTransactionsApi;
}(BaseAPI));
export { ScheduledTransactionsApi };
/**
 * TransactionsApi - fetch parameter creator
 * @export
 */
export var TransactionsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Creates a single transaction or multiple transactions.  If you provide a body containing a `transaction` object, a single transaction will be created and if you provide a body containing a `transactions` array, multiple transactions will be created.  Scheduled transactions cannot be created on this endpoint.
         * @summary Create a single transaction or multiple transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {SaveTransactionsWrapper} data - The transaction or transactions to create.  To create a single transaction you can specify a value for the `transaction` object and to create multiple transactions you can specify an array of `transactions`.  It is expected that you will only provide a value for one of these objects.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        createTransaction: function (budget_id, data, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling createTransaction.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data', 'Required parameter data was null or undefined when calling createTransaction.');
            }
            var localVarPath = "/budgets/{budget_id}/transactions"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = JSON.stringify(data || {});
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single transaction
         * @summary Single transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} transaction_id - The id of the transaction
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionById: function (budget_id, transaction_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getTransactionById.');
            }
            // verify required parameter 'transaction_id' is not null or undefined
            if (transaction_id === null || transaction_id === undefined) {
                throw new RequiredError('transaction_id', 'Required parameter transaction_id was null or undefined when calling getTransactionById.');
            }
            var localVarPath = "/budgets/{budget_id}/transactions/{transaction_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "transaction_id" + "}", encodeURIComponent(String(transaction_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns budget transactions
         * @summary List transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactions: function (budget_id, since_date, type, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getTransactions.');
            }
            var localVarPath = "/budgets/{budget_id}/transactions"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (since_date !== undefined) {
                localVarQueryParameter['since_date'] = convertDateToFullDateStringFormat(since_date);
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all transactions for a specified account
         * @summary List account transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} account_id - The id of the account
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByAccount: function (budget_id, account_id, since_date, type, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getTransactionsByAccount.');
            }
            // verify required parameter 'account_id' is not null or undefined
            if (account_id === null || account_id === undefined) {
                throw new RequiredError('account_id', 'Required parameter account_id was null or undefined when calling getTransactionsByAccount.');
            }
            var localVarPath = "/budgets/{budget_id}/accounts/{account_id}/transactions"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "account_id" + "}", encodeURIComponent(String(account_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (since_date !== undefined) {
                localVarQueryParameter['since_date'] = convertDateToFullDateStringFormat(since_date);
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all transactions for a specified category
         * @summary List category transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} category_id - The id of the category
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByCategory: function (budget_id, category_id, since_date, type, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getTransactionsByCategory.');
            }
            // verify required parameter 'category_id' is not null or undefined
            if (category_id === null || category_id === undefined) {
                throw new RequiredError('category_id', 'Required parameter category_id was null or undefined when calling getTransactionsByCategory.');
            }
            var localVarPath = "/budgets/{budget_id}/categories/{category_id}/transactions"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "category_id" + "}", encodeURIComponent(String(category_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (since_date !== undefined) {
                localVarQueryParameter['since_date'] = convertDateToFullDateStringFormat(since_date);
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all transactions for a specified payee
         * @summary List payee transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - The id of the payee
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByPayee: function (budget_id, payee_id, since_date, type, last_knowledge_of_server, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling getTransactionsByPayee.');
            }
            // verify required parameter 'payee_id' is not null or undefined
            if (payee_id === null || payee_id === undefined) {
                throw new RequiredError('payee_id', 'Required parameter payee_id was null or undefined when calling getTransactionsByPayee.');
            }
            var localVarPath = "/budgets/{budget_id}/payees/{payee_id}/transactions"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "payee_id" + "}", encodeURIComponent(String(payee_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (since_date !== undefined) {
                localVarQueryParameter['since_date'] = convertDateToFullDateStringFormat(since_date);
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            if (last_knowledge_of_server !== undefined) {
                localVarQueryParameter['last_knowledge_of_server'] = last_knowledge_of_server;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Imports available transactions on all linked accounts for the given budget.  Linked accounts allow transactions to be imported directly from a specified financial institution and this endpoint initiates that import.  Sending a request to this endpoint is the equivalent of clicking \"Import\" on each account in the web application or tapping the \"New Transactions\" banner in the mobile applications.  The response for this endpoint contains the transaction ids that have been imported.
         * @summary Import transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        importTransactions: function (budget_id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling importTransactions.');
            }
            var localVarPath = "/budgets/{budget_id}/transactions/import"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a single transaction
         * @summary Updates an existing transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} transaction_id - The id of the transaction
         * @param {SaveTransactionWrapper} data - The transaction to update
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateTransaction: function (budget_id, transaction_id, data, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling updateTransaction.');
            }
            // verify required parameter 'transaction_id' is not null or undefined
            if (transaction_id === null || transaction_id === undefined) {
                throw new RequiredError('transaction_id', 'Required parameter transaction_id was null or undefined when calling updateTransaction.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data', 'Required parameter data was null or undefined when calling updateTransaction.');
            }
            var localVarPath = "/budgets/{budget_id}/transactions/{transaction_id}"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)))
                .replace("{" + "transaction_id" + "}", encodeURIComponent(String(transaction_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = JSON.stringify(data || {});
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple transactions, by `id` or `import_id`.
         * @summary Update multiple transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {UpdateTransactionsWrapper} data - The transactions to update. Each transaction must have either an `id` or `import_id` specified. If `id` is specified as null an `import_id` value can be provided which will allow transaction(s) to be updated by their `import_id`. If an `id` is specified, it will always be used for lookup.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateTransactions: function (budget_id, data, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'budget_id' is not null or undefined
            if (budget_id === null || budget_id === undefined) {
                throw new RequiredError('budget_id', 'Required parameter budget_id was null or undefined when calling updateTransactions.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data', 'Required parameter data was null or undefined when calling updateTransactions.');
            }
            var localVarPath = "/budgets/{budget_id}/transactions"
                .replace("{" + "budget_id" + "}", encodeURIComponent(String(budget_id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = JSON.stringify(data || {});
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TransactionsApi - functional programming interface
 * @export
 */
export var TransactionsApiFp = function (configuration) {
    return {
        /**
         * Creates a single transaction or multiple transactions.  If you provide a body containing a `transaction` object, a single transaction will be created and if you provide a body containing a `transactions` array, multiple transactions will be created.  Scheduled transactions cannot be created on this endpoint.
         * @summary Create a single transaction or multiple transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {SaveTransactionsWrapper} data - The transaction or transactions to create.  To create a single transaction you can specify a value for the `transaction` object and to create multiple transactions you can specify an array of `transactions`.  It is expected that you will only provide a value for one of these objects.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        createTransaction: function (budget_id, data, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).createTransaction(budget_id, data, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns a single transaction
         * @summary Single transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} transaction_id - The id of the transaction
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionById: function (budget_id, transaction_id, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).getTransactionById(budget_id, transaction_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns budget transactions
         * @summary List transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactions: function (budget_id, since_date, type, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).getTransactions(budget_id, since_date, type, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all transactions for a specified account
         * @summary List account transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} account_id - The id of the account
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByAccount: function (budget_id, account_id, since_date, type, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).getTransactionsByAccount(budget_id, account_id, since_date, type, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all transactions for a specified category
         * @summary List category transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} category_id - The id of the category
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByCategory: function (budget_id, category_id, since_date, type, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).getTransactionsByCategory(budget_id, category_id, since_date, type, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Returns all transactions for a specified payee
         * @summary List payee transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - The id of the payee
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByPayee: function (budget_id, payee_id, since_date, type, last_knowledge_of_server, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).getTransactionsByPayee(budget_id, payee_id, since_date, type, last_knowledge_of_server, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Imports available transactions on all linked accounts for the given budget.  Linked accounts allow transactions to be imported directly from a specified financial institution and this endpoint initiates that import.  Sending a request to this endpoint is the equivalent of clicking \"Import\" on each account in the web application or tapping the \"New Transactions\" banner in the mobile applications.  The response for this endpoint contains the transaction ids that have been imported.
         * @summary Import transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        importTransactions: function (budget_id, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).importTransactions(budget_id, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Updates a single transaction
         * @summary Updates an existing transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} transaction_id - The id of the transaction
         * @param {SaveTransactionWrapper} data - The transaction to update
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateTransaction: function (budget_id, transaction_id, data, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).updateTransaction(budget_id, transaction_id, data, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
        /**
         * Updates multiple transactions, by `id` or `import_id`.
         * @summary Update multiple transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {UpdateTransactionsWrapper} data - The transactions to update. Each transaction must have either an `id` or `import_id` specified. If `id` is specified as null an `import_id` value can be provided which will allow transaction(s) to be updated by their `import_id`. If an `id` is specified, it will always be used for lookup.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateTransactions: function (budget_id, data, options) {
            var _this = this;
            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).updateTransactions(budget_id, data, options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * TransactionsApi - factory interface
 * @export
 */
export var TransactionsApiFactory = function (configuration) {
    return {
        /**
         * Creates a single transaction or multiple transactions.  If you provide a body containing a `transaction` object, a single transaction will be created and if you provide a body containing a `transactions` array, multiple transactions will be created.  Scheduled transactions cannot be created on this endpoint.
         * @summary Create a single transaction or multiple transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {SaveTransactionsWrapper} data - The transaction or transactions to create.  To create a single transaction you can specify a value for the `transaction` object and to create multiple transactions you can specify an array of `transactions`.  It is expected that you will only provide a value for one of these objects.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        createTransaction: function (budget_id, data, options) {
            return TransactionsApiFp(configuration).createTransaction(budget_id, data, options)();
        },
        /**
         * Returns a single transaction
         * @summary Single transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} transaction_id - The id of the transaction
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionById: function (budget_id, transaction_id, options) {
            return TransactionsApiFp(configuration).getTransactionById(budget_id, transaction_id, options)();
        },
        /**
         * Returns budget transactions
         * @summary List transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactions: function (budget_id, since_date, type, last_knowledge_of_server, options) {
            return TransactionsApiFp(configuration).getTransactions(budget_id, since_date, type, last_knowledge_of_server, options)();
        },
        /**
         * Returns all transactions for a specified account
         * @summary List account transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} account_id - The id of the account
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByAccount: function (budget_id, account_id, since_date, type, last_knowledge_of_server, options) {
            return TransactionsApiFp(configuration).getTransactionsByAccount(budget_id, account_id, since_date, type, last_knowledge_of_server, options)();
        },
        /**
         * Returns all transactions for a specified category
         * @summary List category transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} category_id - The id of the category
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByCategory: function (budget_id, category_id, since_date, type, last_knowledge_of_server, options) {
            return TransactionsApiFp(configuration).getTransactionsByCategory(budget_id, category_id, since_date, type, last_knowledge_of_server, options)();
        },
        /**
         * Returns all transactions for a specified payee
         * @summary List payee transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} payee_id - The id of the payee
         * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
         * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
         * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getTransactionsByPayee: function (budget_id, payee_id, since_date, type, last_knowledge_of_server, options) {
            return TransactionsApiFp(configuration).getTransactionsByPayee(budget_id, payee_id, since_date, type, last_knowledge_of_server, options)();
        },
        /**
         * Imports available transactions on all linked accounts for the given budget.  Linked accounts allow transactions to be imported directly from a specified financial institution and this endpoint initiates that import.  Sending a request to this endpoint is the equivalent of clicking \"Import\" on each account in the web application or tapping the \"New Transactions\" banner in the mobile applications.  The response for this endpoint contains the transaction ids that have been imported.
         * @summary Import transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        importTransactions: function (budget_id, options) {
            return TransactionsApiFp(configuration).importTransactions(budget_id, options)();
        },
        /**
         * Updates a single transaction
         * @summary Updates an existing transaction
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {string} transaction_id - The id of the transaction
         * @param {SaveTransactionWrapper} data - The transaction to update
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateTransaction: function (budget_id, transaction_id, data, options) {
            return TransactionsApiFp(configuration).updateTransaction(budget_id, transaction_id, data, options)();
        },
        /**
         * Updates multiple transactions, by `id` or `import_id`.
         * @summary Update multiple transactions
         * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
         * @param {UpdateTransactionsWrapper} data - The transactions to update. Each transaction must have either an `id` or `import_id` specified. If `id` is specified as null an `import_id` value can be provided which will allow transaction(s) to be updated by their `import_id`. If an `id` is specified, it will always be used for lookup.
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        updateTransactions: function (budget_id, data, options) {
            return TransactionsApiFp(configuration).updateTransactions(budget_id, data, options)();
        },
    };
};
/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
var TransactionsApi = /** @class */ (function (_super) {
    __extends(TransactionsApi, _super);
    function TransactionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Creates a single transaction or multiple transactions.  If you provide a body containing a `transaction` object, a single transaction will be created and if you provide a body containing a `transactions` array, multiple transactions will be created.  Scheduled transactions cannot be created on this endpoint.
     * @summary Create a single transaction or multiple transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {SaveTransactionsWrapper} data - The transaction or transactions to create.  To create a single transaction you can specify a value for the `transaction` object and to create multiple transactions you can specify an array of `transactions`.  It is expected that you will only provide a value for one of these objects.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.createTransaction = function (budget_id, data, options) {
        return TransactionsApiFp(this.configuration).createTransaction(budget_id, data, options)();
    };
    /**
     * Returns a single transaction
     * @summary Single transaction
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} transaction_id - The id of the transaction
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.getTransactionById = function (budget_id, transaction_id, options) {
        return TransactionsApiFp(this.configuration).getTransactionById(budget_id, transaction_id, options)();
    };
    /**
     * Returns budget transactions
     * @summary List transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
     * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.getTransactions = function (budget_id, since_date, type, last_knowledge_of_server, options) {
        return TransactionsApiFp(this.configuration).getTransactions(budget_id, since_date, type, last_knowledge_of_server, options)();
    };
    /**
     * Returns all transactions for a specified account
     * @summary List account transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} account_id - The id of the account
     * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
     * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.getTransactionsByAccount = function (budget_id, account_id, since_date, type, last_knowledge_of_server, options) {
        return TransactionsApiFp(this.configuration).getTransactionsByAccount(budget_id, account_id, since_date, type, last_knowledge_of_server, options)();
    };
    /**
     * Returns all transactions for a specified category
     * @summary List category transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} category_id - The id of the category
     * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
     * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.getTransactionsByCategory = function (budget_id, category_id, since_date, type, last_knowledge_of_server, options) {
        return TransactionsApiFp(this.configuration).getTransactionsByCategory(budget_id, category_id, since_date, type, last_knowledge_of_server, options)();
    };
    /**
     * Returns all transactions for a specified payee
     * @summary List payee transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} payee_id - The id of the payee
     * @param {Date} [since_date] - If specified, only transactions on or after this date will be included.  The date should be ISO formatted (e.g. 2016-12-30).
     * @param {&#39;uncategorized&#39; | &#39;unapproved&#39;} [type] - If specified, only transactions of the specified type will be included. \"uncategorized\" and \"unapproved\" are currently supported.
     * @param {number} [last_knowledge_of_server] - The starting server knowledge.  If provided, only entities that have changed since `last_knowledge_of_server` will be included.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.getTransactionsByPayee = function (budget_id, payee_id, since_date, type, last_knowledge_of_server, options) {
        return TransactionsApiFp(this.configuration).getTransactionsByPayee(budget_id, payee_id, since_date, type, last_knowledge_of_server, options)();
    };
    /**
     * Imports available transactions on all linked accounts for the given budget.  Linked accounts allow transactions to be imported directly from a specified financial institution and this endpoint initiates that import.  Sending a request to this endpoint is the equivalent of clicking \"Import\" on each account in the web application or tapping the \"New Transactions\" banner in the mobile applications.  The response for this endpoint contains the transaction ids that have been imported.
     * @summary Import transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.importTransactions = function (budget_id, options) {
        return TransactionsApiFp(this.configuration).importTransactions(budget_id, options)();
    };
    /**
     * Updates a single transaction
     * @summary Updates an existing transaction
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {string} transaction_id - The id of the transaction
     * @param {SaveTransactionWrapper} data - The transaction to update
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.updateTransaction = function (budget_id, transaction_id, data, options) {
        return TransactionsApiFp(this.configuration).updateTransaction(budget_id, transaction_id, data, options)();
    };
    /**
     * Updates multiple transactions, by `id` or `import_id`.
     * @summary Update multiple transactions
     * @param {string} budget_id - The id of the budget. \"last-used\" can be used to specify the last used budget and \"default\" can be used if default budget selection is enabled (see: https://api.youneedabudget.com/#oauth-default-budget).
     * @param {UpdateTransactionsWrapper} data - The transactions to update. Each transaction must have either an `id` or `import_id` specified. If `id` is specified as null an `import_id` value can be provided which will allow transaction(s) to be updated by their `import_id`. If an `id` is specified, it will always be used for lookup.
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.updateTransactions = function (budget_id, data, options) {
        return TransactionsApiFp(this.configuration).updateTransactions(budget_id, data, options)();
    };
    return TransactionsApi;
}(BaseAPI));
export { TransactionsApi };
/**
 * UserApi - fetch parameter creator
 * @export
 */
export var UserApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Returns authenticated user information
         * @summary User info
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getUser: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/user";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter["User-Agent"] = USER_AGENT;
            localVarHeaderParameter["Accept"] = "application/json";
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
export var UserApiFp = function (configuration) {
    return {
        /**
         * Returns authenticated user information
         * @summary User info
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getUser: function (options) {
            var _this = this;
            var localVarFetchArgs = UserApiFetchParamCreator(configuration).getUser(options);
            return function (fetchFunction) {
                if (fetchFunction === void 0) { fetchFunction = fetch; }
                return fetchFunction(configuration.basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var apiResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response.status >= 200 && response.status < 300)) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.json()];
                            case 1:
                                apiResponse = _a.sent();
                                apiResponse.rateLimit = response.headers.get("X-Rate-Limit");
                                return [2 /*return*/, apiResponse];
                            case 2: return [2 /*return*/, response.json().then(function (e) {
                                    return Promise.reject(e);
                                })];
                        }
                    });
                }); });
            };
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
export var UserApiFactory = function (configuration) {
    return {
        /**
         * Returns authenticated user information
         * @summary User info
         * @param {*} [options] - Override http request options.
         * @throws {RequiredError}
         */
        getUser: function (options) {
            return UserApiFp(configuration).getUser(options)();
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns authenticated user information
     * @summary User info
     * @param {*} [options] - Override http request options.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getUser = function (options) {
        return UserApiFp(this.configuration).getUser(options)();
    };
    return UserApi;
}(BaseAPI));
export { UserApi };
