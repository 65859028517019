(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (global = global || self, factory(global.Microstates = {}));
}(this, function (exports) { 'use strict';

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

  function unwrapExports (x) {
  	return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
  }

  function createCommonjsModule(fn, module) {
  	return module = { exports: {} }, fn(module, module.exports), module.exports;
  }

  var invariant = function (condition, format, a, b, c, d, e, f) {

    if (!condition) {
      var error;

      if (format === undefined) {
        error = new Error('Minified exception occurred; use the non-minified dev environment ' + 'for the full error message and additional helpful warnings.');
      } else {
        var args = [a, b, c, d, e, f];
        var argIndex = 0;
        error = new Error(format.replace(/%s/g, function () {
          return args[argIndex++];
        }));
        error.name = 'Invariant Violation';
      }

      error.framesToPop = 1;
      throw error;
    }
  };

  var invariant_1 = invariant;

  var funcadelic_cjs = createCommonjsModule(function (module, exports) {

  Object.defineProperty(exports, '__esModule', {
    value: true
  });

  function _interopDefault(ex) {
    return ex && typeof ex === 'object' && 'default' in ex ? ex['default'] : ex;
  }

  var invariant = _interopDefault(invariant_1);

  const keys = Object.keys,
        getOwnPropertyDescriptors = Object.getOwnPropertyDescriptors,
        defineProperty = Object.defineProperty;
  invariant(getOwnPropertyDescriptors, `funcadelic.js requires Object.getOwnPropertyDescriptors. See https://github.com/cowboyd/funcadelic.js#compatibility`);
  invariant("name" in Function.prototype && "name" in function x() {}, `funcadelic.js requires Function.name. See https://github.com/cowboyd/funcadelic.js#compatibility`);
  const VERSION = 0;
  let uniqueTag = 0;

  function type(Class) {
    let name = Class.name;

    if (!name) {
      throw new Error('invalid typeclass name: ' + name);
    }

    let symbolName = `@@funcadelic-${VERSION}/${name}/${uniqueTag++}`;
    let symbol = Symbol[symbolName] ? Symbol[symbolName] : Symbol[symbolName] = Symbol(symbolName);

    Class.for = function _for(value) {
      let i = value[symbol];

      if (!i) {
        throw new Error(`No instance found on ${value} of typeclass ${name}`);
      }

      return i;
    };

    Class.instance = function (constructor, methods) {
      defineProperty(constructor.prototype, symbol, {
        value: methods,
        configurable: true,
        enumerable: false
      });
    };

    Class.symbolName = symbolName;
    Class.symbol = symbol;
    let properties = getOwnPropertyDescriptors(Class.prototype);
    keys(properties).filter(key => key != 'constructor').forEach(key => {
      Class.prototype[key] = Class.prototype[key].bind(Class.for);
    });
    return Class;
  }

  const Semigroup = type(class Semigroup {
    static get name() {
      return 'Semigroup';
    }

    append(left, right) {
      let _this = this(left),
          append = _this.append;

      return append(left, right);
    }

  });
  const append = Semigroup.prototype.append;
  const Foldable = type(class Foldable {
    static get name() {
      return 'Foldable';
    }

    foldr(fn, initial, foldable) {
      let _this = this(foldable),
          foldr = _this.foldr;

      return foldr(fn, initial, foldable);
    }

    foldl(fn, initial, foldable) {
      let _this2 = this(foldable),
          foldl = _this2.foldl;

      return foldl(fn, initial, foldable);
    }

    size(foldable) {
      let _this3 = this(foldable),
          foldr = _this3.foldr;

      return foldr(len => len + 1, 0, foldable);
    }

  });
  const _Foldable$prototype = Foldable.prototype,
        foldr = _Foldable$prototype.foldr,
        foldl = _Foldable$prototype.foldl,
        size = _Foldable$prototype.size;
  const Stable = Symbol('Stable');

  function stable(fn) {
    switch (fn.length) {
      case 0:
        return thunk(fn);

      case 1:
        return stableOne(fn);

      default:
        throw new Error(`Cannot (yet) make functions with ${fn.length} stable`);
    }
  }

  function thunk(fn) {
    if (fn[Stable]) {
      return fn;
    }

    let evaluated = false;
    let result = undefined;

    function evaluate() {
      if (evaluated) {
        return result;
      } else {
        result = fn.call(this);
        evaluated = true;
        return result;
      }
    }

    evaluate[Stable] = true;
    return evaluate;
  }

  function stableOne(fn) {
    if (fn[Stable]) {
      return fn;
    }

    let cache = new WeakMap();

    function stabilizedOne(argument) {
      if (!cache.has(argument)) {
        cache.set(argument, fn(argument));
      }

      return cache.get(argument);
    }

    stabilizedOne[Stable] = true;
    return stabilizedOne;
  }

  const getPrototypeOf = Object.getPrototypeOf,
        getOwnPropertyDescriptors$1 = Object.getOwnPropertyDescriptors,
        getOwnPropertySymbols = Object.getOwnPropertySymbols;
  Semigroup.instance(Object, {
    append(o1, o2) {
      let properties = assign({}, getOwnPropertyDescriptors$1(o1), getOwnPropertyDescriptors$1(o2));
      return Object.create(getPrototypeOf(o1), stableize(properties));
    }

  });

  function assign(target, a, b) {
    function copy(source) {
      let keys = allKeys(source);
      let totalKeys = keys.length;

      for (let j = 0; j < totalKeys; j++) {
        let key = keys[j];
        target[key] = source[key];
      }
    }

    copy(a);
    copy(b);
    return target;
  }

  function stableize(properties) {
    return foldl((descriptors, key) => {
      let descriptor = properties[key];

      if (!descriptor.get) {
        descriptors[key] = descriptor;
      } else {
        let getter = descriptor.get;
        let cached = stable(instance => getter.call(instance));

        descriptor.get = function () {
          return cached(this);
        };

        descriptors[key] = descriptor;
      }

      return descriptors;
    }, {}, allKeys(properties));
  }

  function allKeys(o) {
    return Object.keys(o).concat(getOwnPropertySymbols(o));
  }

  Semigroup.instance(Array, {
    append(a1, a2) {
      return a1.concat(a2);
    }

  });
  const Functor = type(class Functor {
    static get name() {
      return 'Functor';
    }

    map(fn, f) {
      let _this = this(f),
          map = _this.map;

      return map(fn, f);
    }

  });
  const map = Functor.prototype.map;
  const Monoid = type(class Monoid extends Semigroup {
    static get name() {
      return 'Monoid';
    }

    reduce(M, values) {
      let empty = this(M.prototype).empty();
      return foldl((reduction, value) => append(reduction, value), empty, values);
    }

  });

  Monoid.create = function create(Definition) {
    class Monoidal extends Definition {
      constructor(value = Monoidal.prototype.empty()) {
        super();
        this.value = value;
      }

    }

    Monoidal.reduce = list => reduce(Monoidal, map(i => new Monoidal(i), list)).value;

    Semigroup.instance(Monoidal, {
      append(a, b) {
        return new Monoidal(Monoidal.prototype.append(a.value, b.value));
      }

    });
    Monoid.instance(Monoidal, {
      empty() {
        return new Monoidal();
      }

    });
    return Monoidal;
  };

  const reduce = Monoid.prototype.reduce;
  Monoid.instance(Object, {
    empty() {
      return {};
    }

  });
  Monoid.instance(Array, {
    empty() {
      return [];
    }

  });
  const getPrototypeOf$1 = Object.getPrototypeOf,
        keys$1 = Object.keys;
  Functor.instance(Object, {
    map(fn, object) {
      let descriptors = keys$1(object).reduce((descriptors, key) => {
        descriptors[key] = {
          configurable: true,
          enumerable: true,
          get: stable(() => fn(object[key], key))
        };
        return descriptors;
      }, {});
      return Object.create(getPrototypeOf$1(object), descriptors);
    }

  });
  Functor.instance(Array, {
    map(fn, array) {
      return array.map(fn);
    }

  });
  Functor.instance(Promise, {
    map(fn, promise) {
      return promise.then(fn);
    }

  });

  function curry(f) {
    return function curried(...t) {
      if (t.length === 0) return curried;
      if (t.length >= f.length) return f(...t);
      return curried.bind(this, ...t);
    };
  }

  const Applicative = type(class Applicative extends Functor {
    static get name() {
      return 'Applicative';
    }

    pure(Type, value) {
      return this(Type.prototype).pure(value);
    }

    apply(Type, fn, list) {
      let _this = this(Type.prototype),
          pure = _this.pure,
          applyOne = _this.applyOne;

      let initial = pure(curry(fn));
      return foldl((left, right) => applyOne(left, right), initial, list);
    }

    applyOne(left, right) {
      this(left).applyOne(right);
    }

  });
  const _Applicative$prototyp = Applicative.prototype,
        pure = _Applicative$prototyp.pure,
        apply = _Applicative$prototyp.apply,
        applyOne = _Applicative$prototyp.applyOne;
  Applicative.instance(Promise, {
    pure(value) {
      return Promise.resolve(value);
    },

    applyOne(left, right) {
      return Promise.all([left, right]).then(([fn, value]) => fn(value));
    }

  });
  const Monad = type(class Monad extends Applicative {
    static get name() {
      return 'Monad';
    }

    flatMap(fn, m) {
      return this(m).flatMap(fn, m);
    }

  });
  const flatMap = Monad.prototype.flatMap;
  Monad.instance(Array, {
    flatMap(fn, array) {
      return reduce(Array, map(fn, array));
    }

  });
  Monad.instance(Promise, {
    flatMap(fn, promise) {
      return promise.then(fn);
    }

  });
  Foldable.instance(Array, {
    foldr(fn, initial, array) {
      return array.reduceRight(fn, initial);
    },

    foldl(fn, initial, array) {
      return array.reduce(fn, initial);
    }

  });
  const keys$2 = Object.keys;
  Foldable.instance(Object, {
    foldr(fn, initial, object) {
      return keys$2(object).reduceRight((memo, key) => fn(memo, {
        key,

        get value() {
          return object[key];
        }

      }), initial);
    },

    foldl(fn, initial, object) {
      return keys$2(object).reduce((memo, key) => fn(memo, {
        key,

        get value() {
          return object[key];
        }

      }), initial);
    }

  });
  const Filterable = type(class Filterable {
    static get name() {
      return 'Filterable';
    }

    filter(fn, f) {
      return this(f).filter(fn, f);
    }

  });
  const filter = Filterable.prototype.filter;
  const create = Object.create,
        getPrototypeOf$2 = Object.getPrototypeOf;
  Filterable.instance(Object, {
    filter(fn, object) {
      let instance = create(getPrototypeOf$2(object));
      return foldr((filtered, entry) => {
        if (fn(entry)) {
          return append(filtered, {
            get [entry.key]() {
              return entry.value;
            }

          });
        } else {
          return filtered;
        }
      }, instance, object);
    }

  });
  Filterable.instance(Array, {
    filter(fn, array) {
      return array.filter(fn);
    }

  });

  class Chain {
    map(fn) {
      return new Thunk(() => map(fn, this.valueOf()));
    }

    flatMap(fn) {
      return new Thunk(() => flatMap(fn, this.valueOf()));
    }

    filter(fn) {
      return new Thunk(() => filter(fn, this.valueOf()));
    }

    append(thing) {
      return new Thunk(() => append(this.valueOf(), thing));
    }

    tap(fn) {
      fn(this.valueOf());
      return this;
    }

  }

  class Value extends Chain {
    constructor(value) {
      super();
      this.value = value;
    }

    valueOf() {
      return this.value;
    }

  }

  class Thunk extends Chain {
    constructor(fn) {
      super();
      this.fn = stable(fn);
    }

    valueOf() {
      return this.fn();
    }

  }

  function chain(value) {
    return new Value(value);
  }

  exports.type = type;
  exports.Semigroup = Semigroup;
  exports.append = append;
  exports.Monoid = Monoid;
  exports.reduce = reduce;
  exports.Functor = Functor;
  exports.map = map;
  exports.Applicative = Applicative;
  exports.pure = pure;
  exports.apply = apply;
  exports.Monad = Monad;
  exports.flatMap = flatMap;
  exports.Foldable = Foldable;
  exports.foldr = foldr;
  exports.foldl = foldl;
  exports.Filterable = Filterable;
  exports.filter = filter;
  exports.stable = stable;
  exports.chain = chain;
  });

  unwrapExports(funcadelic_cjs);
  var funcadelic_cjs_1 = funcadelic_cjs.type;
  var funcadelic_cjs_2 = funcadelic_cjs.Semigroup;
  var funcadelic_cjs_3 = funcadelic_cjs.append;
  var funcadelic_cjs_4 = funcadelic_cjs.Monoid;
  var funcadelic_cjs_5 = funcadelic_cjs.reduce;
  var funcadelic_cjs_6 = funcadelic_cjs.Functor;
  var funcadelic_cjs_7 = funcadelic_cjs.map;
  var funcadelic_cjs_8 = funcadelic_cjs.Applicative;
  var funcadelic_cjs_9 = funcadelic_cjs.pure;
  var funcadelic_cjs_10 = funcadelic_cjs.apply;
  var funcadelic_cjs_11 = funcadelic_cjs.Monad;
  var funcadelic_cjs_12 = funcadelic_cjs.flatMap;
  var funcadelic_cjs_13 = funcadelic_cjs.Foldable;
  var funcadelic_cjs_14 = funcadelic_cjs.foldr;
  var funcadelic_cjs_15 = funcadelic_cjs.foldl;
  var funcadelic_cjs_16 = funcadelic_cjs.Filterable;
  var funcadelic_cjs_17 = funcadelic_cjs.filter;
  var funcadelic_cjs_18 = funcadelic_cjs.stable;
  var funcadelic_cjs_19 = funcadelic_cjs.chain;

  function CachedProperty(key, reify) {
    let enumerable = true;
    let configurable = true;
    return {
      enumerable,
      configurable,

      get() {
        let value = reify(this);
        Object.defineProperty(this, key, {
          enumerable,
          value
        });
        return value;
      }

    };
  }

  var _class, _temp;
  const Tree = funcadelic_cjs_1((_temp = _class = class {
    childAt(key, parent) {
      if (parent[Tree.symbol]) {
        return this(parent).childAt(key, parent);
      } else {
        return parent[key];
      }
    }

    defineChildren(fn, parent) {
      if (parent[Tree.symbol]) {
        return this(parent).defineChildren(fn, parent);
      } else {
        for (let property of Object.keys(parent)) {
          Object.defineProperty(parent, property, CachedProperty(property, () => fn(property, parent)));
        }
      }
    }

  }, _defineProperty(_class, "name", 'Tree'), _temp));
  const {
    childAt,
    defineChildren
  } = Tree.prototype;

  class Box {
    static get of() {
      return (...args) => new this(...args);
    }

    static unwrap(box) {
      return box.value;
    }

    constructor(value) {
      this.value = value;
    }

  }

  const {
    unwrap
  } = Box;

  class Id extends Box {}

  class Const extends Box {}

  funcadelic_cjs_6.instance(Id, {
    map(fn, id) {
      return Id.of(fn(id.value));
    }

  });
  funcadelic_cjs_6.instance(Const, {
    map(fn, constant) {
      return constant;
    }

  });
  function compose(f, g) {
    return (...x) => f(g(...x));
  }
  function view(lens, context) {
    let get = compose(unwrap, lens(Const.of));
    return get(context);
  }
  function over(lens, fn, context) {
    let update = compose(unwrap, lens(compose(Id.of, fn)));
    return update(context);
  }
  function set(lens, value, context) {
    return over(lens, () => value, context);
  }
  function Lens(get, set) {
    return f => context => {
      return funcadelic_cjs_7(value => set(value, context), f(get(context)));
    };
  }
  const transparent = Lens(x => x, y => y);
  function At(property, container) {
    let get = context => context != null ? childAt(property, context) : undefined;

    let set = (part, whole) => {
      let context = whole == null ? Array.isArray(container) ? [] : {} : whole;

      if (part === context[property]) {
        return context;
      } else if (Array.isArray(context)) {
        let clone = context.slice();
        clone[Number(property)] = part;
        return clone;
      } else {
        return funcadelic_cjs_2.for(Object).append(context, {
          [property]: part
        });
      }
    };

    return Lens(get, set);
  }
  function Path(path) {
    return path.reduce((lens, key) => compose(lens, At(key)), transparent);
  }

  class Meta {
    constructor(object, value) {
      this.root = object;
      this.lens = transparent;
      this.path = [];
      this.value = value;
      this.source = object;
    }

  }

  _defineProperty(Meta, "symbol", Symbol('Meta'));

  _defineProperty(Meta, "data", At(Meta.symbol));

  _defineProperty(Meta, "lens", compose(Meta.data, At('lens')));

  _defineProperty(Meta, "path", compose(Meta.data, At('path')));

  _defineProperty(Meta, "value", compose(Meta.data, At('value')));

  _defineProperty(Meta, "source", compose(Meta.data, At('source')));

  function metaOf(object) {
    return view(Meta.data, object);
  }
  function valueOf(object) {
    let meta = metaOf(object);
    return meta != null ? meta.value : object;
  }
  function sourceOf(object) {
    return view(Meta.source, object);
  }
  function mount(microstate, substate, key) {
    let parent = view(Meta.data, microstate);
    let prefix = compose(parent.lens, At(key, parent.value));
    return over(Meta.data, meta => ({
      get root() {
        return parent.root;
      },

      get lens() {
        return compose(prefix, meta.lens);
      },

      get path() {
        return parent.path.concat([key]).concat(meta.path);
      },

      get value() {
        return meta.value;
      },

      get source() {
        return meta.source;
      }

    }), substate);
  }

  const {
    getPrototypeOf,
    getOwnPropertyDescriptors,
    assign
  } = Object;
  function methodsOf(Type) {
    return funcadelic_cjs_17(({
      key: name,
      value: desc
    }) => {
      return name !== 'constructor' && typeof name === 'string' && typeof desc.value === 'function';
    }, getAllPropertyDescriptors(Type.prototype));
  }

  function getAllPropertyDescriptors(object) {
    if (object === Object.prototype) {
      return {};
    } else {
      let prototype = getPrototypeOf(object);
      return assign(getAllPropertyDescriptors(prototype), getOwnPropertyDescriptors(object));
    }
  }

  class Primitive {
    get state() {
      return valueOf(this);
    }

  }

  class Any extends Primitive {}

  function query(iterable) {
    return new Query(iterable);
  }
  function map(iterable, fn) {
    return query(iterable).map(fn);
  }
  function filter(iterable, fn) {
    return query(iterable).filter(fn);
  }
  function reduce(iterable, fn, initial) {
    return query(iterable).reduce(fn, initial);
  }
  function find(iterable, fn) {
    return query(iterable).find(fn);
  }

  class Query {
    constructor(iterable) {
      if (typeof iterable[Symbol.iterator] === 'function') {
        this.generator = () => iterable[Symbol.iterator]();
      } else if (typeof iterable === 'function') {
        this.generator = iterable;
      } else {
        throw new Error('Query must be constructed with a generator function or iterable. Received `${iterable}`');
      }
    }

    get length() {
      return reduce(this, sum => sum + 1, 0);
    }

    map(fn) {
      return query(() => {
        let source = this.generator();
        return {
          next() {
            let next = source.next();
            return {
              get done() {
                return next.done;
              },

              get value() {
                return fn(next.value);
              }

            };
          }

        };
      });
    }

    filter(fn) {
      return query(() => {
        let source = this.generator();
        return {
          next() {
            let result;

            function find() {
              if (result != null) {
                return result;
              } else {
                for (result = source.next(); !result.done && !fn(result.value); result = source.next()) {}

                return result;
              }
            }

            return {
              get done() {
                return find().done;
              },

              get value() {
                return find().value;
              }

            };
          }

        };
      });
    }

    reduce(fn, initial) {
      let result = initial;

      for (let item of this) {
        result = fn(result, item);
      }

      return result;
    }

    find(fn) {
      for (let item of this) {
        if (fn(item)) {
          return item;
        }
      }
    }

    [Symbol.iterator]() {
      return this.generator();
    }

  }

  function parameterized(fn) {
    function initialize(...args) {
      let Type = fn(...args);

      if (Type.initialize) {
        Type.initialize();
      }

      return Type;
    }

    let defaultTypeParameters = new Array(fn.length);
    defaultTypeParameters.fill(Any);
    let DefaultType = initialize(...defaultTypeParameters);

    DefaultType.of = (...args) => initialize(...args);

    return DefaultType;
  }

  var ObjectType = parameterized(T => {
    var _class, _temp;

    let _Symbol$iterator;

    return _temp = (_Symbol$iterator = Symbol.iterator, _class = class ObjectType {
      get entries() {
        return reduce(this, (entries, entry) => Object.assign(entries, {
          [entry.key]: entry.value
        }), {});
      }

      get keys() {
        return Object.keys(valueOf(this));
      }

      get values() {
        return query(this).map(entry => entry.value);
      }

      initialize(value) {
        return value == null ? {} : this;
      }

      assign(attrs) {
        return funcadelic_cjs_3(valueOf(this), funcadelic_cjs_7(valueOf, attrs));
      }

      put(name, value) {
        return this.assign({
          [name]: value
        });
      }

      delete(name) {
        return funcadelic_cjs_17(({
          key
        }) => key !== name, valueOf(this));
      }

      map(fn) {
        return funcadelic_cjs_7(value => valueOf(fn(create(T, value))), valueOf(this));
      }

      filter(fn) {
        return funcadelic_cjs_17(({
          key,
          value
        }) => valueOf(fn(create(T, value), key)), valueOf(this));
      }

      [_Symbol$iterator]() {
        let object = this;
        let iterator = Object.keys(valueOf(this))[Symbol.iterator]();
        return {
          next() {
            let next = iterator.next();
            return {
              get done() {
                return next.done;
              },

              get value() {
                if (!next.done) {
                  return new Entry(next.value, childAt(next.value, object));
                } else {
                  return undefined;
                }
              }

            };
          }

        };
      }

      static initialize() {
        Tree.instance(this, {
          childAt(key, object) {
            if (typeof key !== 'string') {
              return object[key];
            } else {
              let value = valueOf(object)[key];
              return mount(object, create(T, value), key);
            }
          },

          defineChildren(fn, object) {
            let generate = object[Symbol.iterator];
            return Object.defineProperty(object, Symbol.iterator, {
              enumerable: false,

              value() {
                let iterator = generate.call(object);
                return {
                  next() {
                    let next = iterator.next();
                    return {
                      get done() {
                        return next.done;
                      },

                      get value() {
                        if (!next.done) {
                          let {
                            key
                          } = next.value;
                          return new Entry(key, fn(key));
                        } else {
                          return undefined;
                        }
                      }

                    };
                  }

                };
              }

            });
          }

        });
      }

    }), _defineProperty(_class, "T", T), _defineProperty(_class, "name", `Object<${T.name}>`), _temp;
  });

  class Entry {
    constructor(key, value) {
      this.key = key;
      this.value = value;
    }

    [Symbol.iterator]() {
      return [this.value, this.key][Symbol.iterator]();
    }

  }

  var ArrayType = parameterized(T => {
    var _class, _temp;

    let _Symbol$iterator;

    return _temp = (_Symbol$iterator = Symbol.iterator, _class = class ArrayType {
      static get name() {
        return `Array<${T.name}>`;
      }

      get length() {
        return valueOf(this).length;
      }

      initialize(value) {
        if (value == null) {
          return [];
        } else if (Array.isArray(value) || value[Symbol.iterator]) {
          return value;
        } else {
          return [value];
        }
      }

      push(value) {
        return [...valueOf(this), valueOf(value)];
      }

      pop() {
        return valueOf(this).slice(0, -1);
      }

      shift() {
        let [, ...rest] = valueOf(this);
        return rest;
      }

      unshift(value) {
        return [valueOf(value), ...valueOf(this)];
      }

      slice(begin, end) {
        let list = valueOf(this);
        let result = list.slice(begin, end);
        return list.length === result.length ? this : result;
      }

      sort(compareFn) {
        let init = valueOf(this);
        let result = [...init].sort(compareFn);
        return init.every((member, idx) => result[idx] === member) ? this : result;
      }

      filter(fn) {
        let list = valueOf(this);
        let result = list.filter(member => fn(create(T, member)));
        return list.length === result.length ? this : result;
      }

      map(fn) {
        let list = valueOf(this);
        return list.reduce((result, member, index) => {
          let mapped = valueOf(fn(create(T, member)));
          return set(At(index, result), mapped, result);
        }, list);
      }

      remove(item) {
        return this.filter(s => valueOf(s) !== valueOf(item));
      }

      clear() {
        return [];
      }

      [_Symbol$iterator]() {
        let array = this;
        let iterator = valueOf(this)[Symbol.iterator]();
        let i = 0;
        return {
          next() {
            let next = iterator.next();
            let index = i++;
            return {
              get done() {
                return next.done;
              },

              get value() {
                if (!next.done) {
                  return childAt(index, array);
                } else {
                  return undefined;
                }
              }

            };
          }

        };
      }

      static initialize() {
        Tree.instance(this, {
          childAt(key, array) {
            if (typeof key === 'number') {
              let value = valueOf(array)[key];
              return mount(array, create(T, value), key);
            } else {
              return array[key];
            }
          },

          defineChildren(fn, array) {
            let generate = array[Symbol.iterator];
            return Object.defineProperty(array, Symbol.iterator, {
              enumerable: false,

              value() {
                let iterator = generate.call(array);
                let i = 0;
                return {
                  next() {
                    let next = iterator.next();
                    let index = i++;
                    return {
                      get done() {
                        return next.done;
                      },

                      get value() {
                        if (!next.done) {
                          return fn(index, next.value, array);
                        } else {
                          return undefined;
                        }
                      }

                    };
                  }

                };
              }

            });
          }

        });
      }

    }), _defineProperty(_class, "T", T), _temp;
  });

  class BooleanType extends Primitive {
    initialize(value) {
      return !!value;
    }

    toggle() {
      return !this.state;
    }

  }

  _defineProperty(BooleanType, "name", "Boolean");

  class NumberType extends Primitive {
    initialize(value) {
      if (value == null) {
        return 0;
      } else if (isNaN(value)) {
        return this;
      } else {
        return Number(value);
      }
    }

    increment(step = 1) {
      return this.state + step;
    }

    decrement(step = 1) {
      return this.state - step;
    }

  }

  _defineProperty(NumberType, "name", "Number");

  class StringType extends Primitive {
    initialize(value) {
      if (value == null) {
        return '';
      } else {
        return String(value);
      }
    }

    concat(value) {
      return this.state.concat(value);
    }

  }

  _defineProperty(StringType, "name", "String");

  class DSL {
    constructor(rules = []) {
      this.rules = rules;
    }

    expand(value) {
      for (let rule of this.rules) {
        let result = rule.call(this, value);

        if (result && typeof result.Type === 'function') {
          return result;
        } else {
          continue;
        }
      }

      return {
        Type: Any,
        value
      };
    }

    use(rule) {
      return new DSL(this.rules.concat(rule));
    }

  }

  var dsl = new DSL().use(function matchBuiltins(value) {
    switch (value) {
      case Object:
        return {
          Type: ObjectType,
          value: {}
        };

      case Array:
        return {
          Type: ArrayType,
          value: []
        };

      case Boolean:
        return {
          Type: BooleanType,
          value: false
        };

      case Number:
        return {
          Type: NumberType,
          value: 0
        };
    }

    if (value === String) {
      return {
        Type: StringType,
        value: ''
      };
    }
  }).use(function matchArrays(value) {
    if (Array.isArray(value) && value.length < 2) {
      let [T] = value;
      let Type = T != null ? ArrayType.of(this.expand(T).Type) : ArrayType;
      return {
        Type,
        value: []
      };
    }
  }).use(function matchObjects(value) {
    if (value != null && typeof value === 'object' && Object.keys(value).length < 2) {
      let [key] = Object.keys(value);

      if (key == null) {
        return {
          Type: ObjectType,
          value: {}
        };
      } else {
        let {
          Type: childType
        } = this.expand(value[key]);

        if (childType.isConstant) {
          return undefined;
        } else {
          return {
            Type: ObjectType.of(childType),
            value: {}
          };
        }
      }
    }
  }).use(function matchCustomTypes(value) {
    if (typeof value === 'function') {
      return {
        Type: value,
        value: undefined
      };
    }
  }).use(function matchConstants(value) {
    return {
      Type: Constant(),
      value
    };
  });

  const Constant = () => {
    var _class, _temp;

    return _temp = _class = class Constant extends Primitive {}, _defineProperty(_class, "isConstant", true), _temp;
  };

  class Relationship {
    constructor(traverse) {
      this.traverse = traverse;
    }

    flatMap(sequence) {
      return new Relationship(edge => {
        let cell = this.traverse(edge);
        let next = sequence(cell);
        return next.traverse(edge);
      });
    }

    map(fn) {
      return this.flatMap(cell => new Relationship(() => fn(cell)));
    }

  }
  function relationship(definition) {
    if (typeof definition === 'function') {
      return new Relationship(definition);
    } else {
      return relationship(({
        value
      }) => {
        if (value != null) {
          return {
            Type: Any,
            value
          };
        } else {
          return {
            Type: Any,
            value: definition
          };
        }
      });
    }
  }
  class Edge {
    constructor(parent, path) {
      this.parent = parent;
      this.path = path;
    }

    get name() {
      let [name] = this.path.slice(-1);
      return name;
    }

    get value() {
      return view(Path(this.path), this.parentValue);
    }

    get parentValue() {
      return valueOf(this.parent);
    }

  }

  class Storage {
    constructor(value, observe = x => x) {
      this.value = value;
      this.observe = observe;
    }

    get() {
      return this.value;
    }

    set(value) {
      if (value !== this.value) {
        this.value = value;
        this.observe();
      }

      return this;
    }

    getPath(path) {
      return view(Path(path), this.value);
    }

    setPath(path, value) {
      return this.set(set(Path(path), value, this.value));
    }

  }

  function Pathmap(Root, ref) {
    let paths = new Storage();

    class Location {
      static allocate(path) {
        let existing = paths.getPath(path.concat(Location.symbol));

        if (existing) {
          return existing;
        } else {
          let location = new Location(path);
          paths.setPath(path, {
            [Location.symbol]: location
          });
          return location;
        }
      }

      get currentValue() {
        return valueOf(this.microstate);
      }

      get reference() {
        if (!this.currentReference || this.currentValue !== valueOf(this.currentReference)) {
          return this.currentReference = this.createReference();
        } else {
          return this.currentReference;
        }
      }

      get microstate() {
        return view(this.lens, create(Root, ref.get()));
      }

      constructor(path) {
        this.path = path;
        this.lens = Path(path);
        this.createReferenceType = funcadelic_cjs_18(Type => {
          let location = this;
          let typeName = Type.name ? Type.name : 'Unknown';

          class Reference extends Type {
            constructor(value) {
              super(value);
              Object.defineProperty(this, Meta.symbol, {
                enumerable: false,
                configurable: true,
                value: new Meta(this, valueOf(value))
              });
              defineChildren(key => Location.allocate(path.concat(key)).reference, this);
            }

          }

          _defineProperty(Reference, "name", `Ref<${typeName}>`);

          _defineProperty(Reference, "location", location);

          for (let methodName of Object.keys(methodsOf(Type)).concat("set")) {
            Reference.prototype[methodName] = (...args) => {
              let microstate = location.microstate;
              let next = microstate[methodName](...args);
              ref.set(valueOf(next));
              return location.reference;
            };
          }

          return Reference;
        });
      }

      createReference() {
        let {
          Type
        } = this.microstate.constructor;
        let Reference = this.createReferenceType(Type);
        return new Reference(this.currentValue);
      }

      get(reference = paths.getPath([Location.symbol, 'reference'])) {
        return reference.constructor.location.reference;
      }

    }

    _defineProperty(Location, "symbol", Symbol('Location'));

    return Location.allocate([]);
  }

  function Identity(microstate, observe = x => x) {
    let {
      Type
    } = microstate.constructor;
    let pathmap = Pathmap(Type, new Storage(valueOf(microstate), () => observe(pathmap.get())));
    return pathmap.get();
  }

  var ponyfill = createCommonjsModule(function (module, exports) {

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports['default'] = symbolObservablePonyfill;

  function symbolObservablePonyfill(root) {
    var result;
    var _Symbol = root.Symbol;

    if (typeof _Symbol === 'function') {
      if (_Symbol.observable) {
        result = _Symbol.observable;
      } else {
        result = _Symbol('observable');
        _Symbol.observable = result;
      }
    } else {
      result = '@@observable';
    }

    return result;
  }
  });

  unwrapExports(ponyfill);

  var lib = createCommonjsModule(function (module, exports) {

  Object.defineProperty(exports, "__esModule", {
    value: true
  });



  var _ponyfill2 = _interopRequireDefault(ponyfill);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      'default': obj
    };
  }

  var root;

  if (typeof self !== 'undefined') {
    root = self;
  } else if (typeof window !== 'undefined') {
    root = window;
  } else if (typeof commonjsGlobal !== 'undefined') {
    root = commonjsGlobal;
  } else {
    root = module;
  }

  var result = (0, _ponyfill2['default'])(root);
  exports['default'] = result;
  });

  var SymbolObservable = unwrapExports(lib);

  function Observable(Microstate) {
    return class extends Microstate {
      [SymbolObservable]() {
        return this['@@observable']();
      }

      ['@@observable']() {
        return {
          subscribe: observer => {
            let next = observer.call ? observer : observer.next.bind(observer);
            let identity = Identity(this, next);
            next(identity);
            return identity;
          },

          [SymbolObservable]() {
            return this;
          }

        };
      }

    };
  }

  function create(InputType = Any, value) {
    let {
      Type
    } = dsl.expand(InputType);
    let Microstate = MicrostateType(Type);
    let microstate = new Microstate(value);

    if (hasOwnProperty(Type.prototype, 'initialize')) {
      return microstate.initialize(value);
    } else {
      return microstate;
    }
  }
  const MicrostateType = funcadelic_cjs_18(function MicrostateType(Type) {
    var _class, _temp;

    if (Type.Type) {
      return Type;
    }

    let Microstate = (_temp = _class = class Microstate extends Observable(Type) {
      constructor(value) {
        super(value);
        Object.defineProperties(this, funcadelic_cjs_7((slot, key) => {
          let relationship = slot instanceof Relationship ? slot : legacy(slot);
          return CachedProperty(key, self => {
            let {
              Type,
              value
            } = relationship.traverse(new Edge(self, [key]));
            return mount(self, create(Type, value), key);
          });
        }, this));
        Object.defineProperty(this, Meta.symbol, {
          enumerable: false,
          configurable: true,
          value: new Meta(this, valueOf(value))
        });
      }

    }, _defineProperty(_class, "name", `Microstate<${Type.name}>`), _defineProperty(_class, "Type", Type), _temp);
    Object.defineProperties(Microstate.prototype, funcadelic_cjs_7(descriptor => {
      return {
        value(...args) {
          let result = descriptor.value.apply(sourceOf(this), args);
          let meta = metaOf(this);
          let previous = valueOf(meta.root);
          let next = set(meta.lens, valueOf(result), previous);

          if (meta.path.length === 0 && metaOf(result) != null) {
            return result;
          }

          if (next === previous) {
            return meta.root;
          } else {
            return create(meta.root.constructor, next);
          }
        }

      };
    }, funcadelic_cjs_3({
      set: {
        value: x => x
      }
    }, methodsOf(Type))));
    return Microstate;
  });

  function legacy(object) {
    let cell;
    let meta = metaOf(object);

    if (meta != null) {
      cell = {
        Type: object.constructor.Type,
        value: valueOf(object)
      };
    } else {
      cell = dsl.expand(object);
    }

    let {
      Type
    } = cell;
    return relationship(cell.value).map(({
      value
    }) => ({
      Type,
      value
    }));
  }

  function hasOwnProperty(target, propertyName) {
    return Object.prototype.hasOwnProperty.call(target, propertyName);
  }

  class Literal {
    initialize(value) {
      if (value == null) {
        return this;
      }

      value = value.valueOf();

      switch (typeof value) {
        case "number":
          return create(Number, value);

        case "string":
          return create(String, value);

        case "boolean":
          return create(Boolean, value);

        default:
          if (Array.isArray(value)) {
            return create([Literal], value);
          } else {
            return create({
              Literal
            }, value);
          }

      }
    }

  }

  var literal = (value => create(Literal, value));

  exports.Any = Any;
  exports.ArrayType = ArrayType;
  exports.BooleanType = BooleanType;
  exports.NumberType = NumberType;
  exports.ObjectType = ObjectType;
  exports.Primitive = Primitive;
  exports.Store = Identity;
  exports.StringType = StringType;
  exports.create = create;
  exports.filter = filter;
  exports.find = find;
  exports.from = literal;
  exports.map = map;
  exports.metaOf = metaOf;
  exports.reduce = reduce;
  exports.relationship = relationship;
  exports.valueOf = valueOf;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
