/**
 * Joins strings to format IDs for compound components.
 *
 * @param args
 */
function makeId() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return args.filter(function (val) {
    return val != null;
  }).join("--");
}

export { makeId };
