import { canUseDOM } from '../../can-use-dom/dist/reach-utils-can-use-dom.esm.js';

/**
 * Get an element's owner document. Useful when components are used in iframes
 * or other environments like dev tools.
 *
 * @param element
 */

function getOwnerDocument(element) {
  return canUseDOM() ? element ? element.ownerDocument : document : null;
}
/**
 * TODO: Remove in 1.0
 */

function getOwnerWindow(element) {
  var ownerDocument = getOwnerDocument(element);
  return ownerDocument ? ownerDocument.defaultView || window : null;
}

export { getOwnerDocument, getOwnerWindow };
